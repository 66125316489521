import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService, Mode } from '@kodit/core/services';
import {
  CreateUslugaCommand,
  IUslugaDto, UpdateRobaCommand,
  UpdateUslugaCommand,
  UslugeClient
} from '@kodit/core/data-api';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
  PdvStopaService,
} from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { FormHelper, UslugaFormService } from 'libs/core/form-definitions/src';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import {round, StopaPdv} from "@kodit/core/shared";

@Component({
  selector: 'kodit-usluga-form',
  templateUrl: './usluga-form.component.html',
  styleUrls: ['./usluga-form.component.scss'],
})
export class UslugaFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Form */
  uslugaForm: FormGroupTypeSafe<IUslugaDto>;
  mode: Mode = Mode.NEW;
  jmDefaultValue: string;

  /** Conditional props */
  shouldShowRazlogPdvIzuzeca : boolean = false;
  shouldSetDefaultValue: boolean = true;
  showStopaPdv: boolean;

  /** Definitive props */
  editModeValue : Mode = Mode.EDIT;

  constructor(
    private _alertService: AlertService,
    private _client: UslugeClient,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _uslugaForm: UslugaFormService,
    private _formHelper: FormHelper,
    private _pdvService: PdvStopaService
  ) {}

  ngOnInit(): void {
    // init form
    this.uslugaForm = this._uslugaForm.GetUslugaFormGroup();
    //

    if (this._dialogConfig.data && this._dialogConfig.data.id) {
      this.getUsluga(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
      this.shouldSetDefaultValue = false;
    }

    if (this._dialogConfig.data && this._dialogConfig.data.naziv) {
      this.uslugaForm.controls.predmetStavkeDto
        .get('naziv')
        .patchValue(this._dialogConfig.data.naziv);
      this.uslugaForm.controls.predmetStavkeDto
        .get('naziv')
        .updateValueAndValidity();
    }

    if (this._dialogConfig.data && this._dialogConfig.data.iznos) {
      this.uslugaForm.controls.predmetStavkeDto
        .get('prodajnaCenaBezPdv')
        .patchValue(this._dialogConfig.data.iznos);
      this.uslugaForm.controls.predmetStavkeDto
        .get('prodajnaCenaBezPdv')
        .updateValueAndValidity();
    }

    if (this._dialogConfig.data && this._dialogConfig.data.jm) {
      this.jmDefaultValue = this._dialogConfig.data.jm;
    }

    // prilikom unosa ukoliko nemamo StopaPDV stavimo da je 0
    this._setShouldShowRazlogPdvIzuzeca(this.uslugaForm.controls?.predmetStavkeDto?.get('stopaPDV').value);

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.saveUsluga();
      })
    );

    //
    this._subs.add(
      this._pdvService.getJeKompanijaUPdv.subscribe((res) => {
        this.showStopaPdv = res;
      })
    );

    this._subs.add(
      this.uslugaForm.controls.predmetStavkeDto.get('stopaPDV').valueChanges.subscribe((res)=>{
        this._setShouldShowRazlogPdvIzuzeca(res);
        this.handleProdajnaCenaChanged(res);
      })
    );

    this._subs.add(
      this._uslugaForm.getTipDokumentaSubjectObservable.subscribe(
        (stopa) => {
          this.handleProdajnaCenaChanged(stopa);
        }
      )
    );
  }

  public saveUsluga() {
    // Posto je nabavna cena mandatory kopiramo prodajnu u nabavnu
    this._copyProdajnaToNabavnaCena();

    if (!this.uslugaForm.valid) {
      // invalid form
      this._formHelper.invalidateForm(this.uslugaForm);
      return;
    }

    if (this.mode === Mode.EDIT) {
      let command = this.uslugaForm.value as UpdateUslugaCommand;

      //zbog prikaza koristimo only-text-input koji prima stringove - externalId je number tipa i zbog toga moramo da prazan string postavimo na null pre slanja requesta
      if(command.predmetStavkeDto.externalId != null && command.predmetStavkeDto.externalId.toString() === ""){
        command.predmetStavkeDto.externalId = null;
      }

      this._subs.add(
        this._client
          .update(
            this.uslugaForm.value.predmetStavkeDto.id,
            command
          )
          .subscribe((result) => {
            this._alertService.addSuccessMsg(
              `Usluga - ${this.uslugaForm.value.predmetStavkeDto.naziv} je uspešno izmenjena.`
            );
            this._dialogRef.close(result);
          })
      );
    } else {
      let command = this.uslugaForm.value as CreateUslugaCommand;

      //zbog prikaza koristimo only-text-input koji prima stringove - externalId je number tipa i zbog toga moramo da prazan string postavimo na null pre slanja requesta
      if(command.predmetStavkeDto.externalId != null && command.predmetStavkeDto.externalId.toString() === ""){
        command.predmetStavkeDto.externalId = null;
      }

      this._subs.add(
        this._client
          .create(command)
          .subscribe((result) => {
            this._alertService.addSuccessMsg(
              `Usluga - ${this.uslugaForm.value.predmetStavkeDto.naziv} je uspešno kreirana`
            );
            this._dialogRef.close(result);
          })
      );
    }
  }

  handleProdajnaCenaChanged(stopa: StopaPdv) {
    const prodajnaCenabBezPdv = this.uslugaForm.controls.predmetStavkeDto.value.prodajnaCenaBezPdv;
    const pdvStopa = stopa != null ? stopa : this.uslugaForm.controls.predmetStavkeDto.value.stopaPDV;
    const prodajnaCenaSaPdv = round(prodajnaCenabBezPdv + prodajnaCenabBezPdv * (pdvStopa / 100), 4);
    this.uslugaForm.controls.predmetStavkeDto.patchValue({
      prodajnaCenaSaPdv: prodajnaCenaSaPdv
    });
  }

  handleProdajnaCenaSaPdvChanged(stopa: StopaPdv) {
    const prodajnaCenaSaPdv = this.uslugaForm.controls.predmetStavkeDto.value.prodajnaCenaSaPdv;
    const pdvStopa = stopa != null ? stopa : this.uslugaForm.controls.predmetStavkeDto.value.stopaPDV;
    const prodajnaCenaBezPdv = round(prodajnaCenaSaPdv / (1+(pdvStopa/100)), 4);
    this.uslugaForm.controls.predmetStavkeDto.patchValue({
      prodajnaCenaBezPdv: prodajnaCenaBezPdv
    });
  }

  private _setShouldShowRazlogPdvIzuzeca(stopa: StopaPdv) {
    if(stopa == StopaPdv.BEZ_PDV){
      this.shouldShowRazlogPdvIzuzeca = true;
    }
    else{
      this.shouldShowRazlogPdvIzuzeca = false;
      this.uslugaForm.controls.predmetStavkeDto.get('oznakaPdvKategorije').patchValue(null);
      this.uslugaForm.controls.predmetStavkeDto.get('razlogPdvIzuzecaId').patchValue(null);
    }
  }

  private _copyProdajnaToNabavnaCena() {
    const prodajnaCena = this.uslugaForm.get(
      'predmetStavkeDto.prodajnaCenaBezPdv'
    ).value;
    this.uslugaForm
      .get('predmetStavkeDto.nabavnaCena')
      .patchValue(prodajnaCena);
  }

  private getUsluga(id: number) {
    this._subs.add(
      this._client.getById(id).subscribe((model) => {
        this.uslugaForm.patchValue(model.data);
        this._uslugaForm.setStopaPdv = this.uslugaForm.value.predmetStavkeDto.stopaPDV;
        this._setShouldShowRazlogPdvIzuzeca(model.data?.predmetStavkeDto?.stopaPDV);
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
