import { remove } from 'lodash';
import { Injectable } from "@angular/core";
import { FormArray, FormControl, Validators } from "@angular/forms";
import { IIzvestajIzlaznihDokumenataFilter, IStrankaMultiSelectItem } from "@kodit/core/data-api";
import { BaseStrankaFormService } from "@kodit/core/form-definitions";
import { FormBuilderTypeSafe, FormGroupTypeSafe } from "angular-typesafe-reactive-forms-helper";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})

export class VeleprodajaIzvestajService {

  private form : FilterForm;
  private _isInitialized: boolean;

  tableData$ = new BehaviorSubject<any>(null);

  constructor(
      private _fb: FormBuilderTypeSafe,
      private _strankaFormService: BaseStrankaFormService
  ){
    this._isInitialized = false;
  }

  getForm(
      model: IIzvestajIzlaznihDokumenataFilter,
    ): FilterForm {
      if (!this._isInitialized) {
        this._init(model);
        this._isInitialized = true;
      }

      return this.form;
  }

  set setTableData(tableData: any){
    this.tableData$.next(tableData);
  }

  get getTableData(): boolean {
  return this.tableData$.getValue();
  }

  get getTableDataObservable(): Observable<any> {
    return this.tableData$.asObservable();
  }

  private _init(model : IIzvestajIzlaznihDokumenataFilter ) {
    this.form = this._fb.group<IIzvestajIzlaznihDokumenataFilter>({
      datumOd:  new FormControl(null, {
        validators: [Validators.required]
            }),
      datumDo:  new FormControl(null, {
        validators: [Validators.required]
      }),
      tipIzvestajaVeleprodaja: new FormControl(model?.tipIzvestajaVeleprodaja),
      podtipIzvestajaVeleprodaja: new FormControl(model?.podtipIzvestajaVeleprodaja),
      stranke: this._strankaFormService.GetStrankeFormArray(
        model?.stranke
      ),
    });
  }

  public _resetujFilter(): void {
    this.form?.controls.datumOd?.reset();
    this.form?.controls.datumDo?.reset();
    if(this.form?.controls.stranke?.value?.length > 0 ){
      (this.form.controls.stranke as FormArray).clear();
    }

  }


}
export declare type FilterForm = FormGroupTypeSafe<IIzvestajIzlaznihDokumenataFilter> | null;
