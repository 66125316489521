import {Component, OnDestroy, OnInit} from '@angular/core';
import {IDokumentOSmanjenjuDto, IDokumentOPovecanjuDto, PrikazIzmenaOsnovice,} from '@kodit/core/data-api';
import {FormGroupTypeSafe} from 'angular-typesafe-reactive-forms-helper';
import {Subscription} from 'rxjs';
import {IzmenaOsnoviceService} from '../../izmena-osnovice.service';
import {FormArray, Validators} from '@angular/forms';

@Component({
  selector: 'kodit-izmena-osnovice',
  templateUrl: './izmena-osnovice.component.html',
  styleUrls: ['./izmena-osnovice.component.scss'],
})
export class IzmenaOsnoviceComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  /**Props */
  periodSelected: boolean;
  racunForm: FormGroupTypeSafe<IDokumentOSmanjenjuDto | IDokumentOPovecanjuDto>;

  constructor(
    private _service: IzmenaOsnoviceService
  ) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this.racunForm = this._service.getFormObject;

    this.periodSelected =
      this.racunForm.value.prikazIzmenaOsnoviceDto.prikazIzmenaOsnovice ===
      PrikazIzmenaOsnovice.FAKTURE_U_PERIODU;

    this._setValidators(
      this.racunForm.value.prikazIzmenaOsnoviceDto.prikazIzmenaOsnovice
    );

    this._subs.add(
      this.racunForm.controls.prikazIzmenaOsnoviceDto
        .get('prikazIzmenaOsnovice')
        .valueChanges.subscribe((res: PrikazIzmenaOsnovice) => {
          this.periodSelected = res == PrikazIzmenaOsnovice.FAKTURE_U_PERIODU;
          this._setValidators(res);

          if(res !== PrikazIzmenaOsnovice.POJEDINACNA){
            this.racunForm.controls.brojReferentnogDokumenta.patchValue(null);
            this.racunForm.controls.brojReferentnogDokumenta.updateValueAndValidity();
          }
        })
    );
  }

  private _setValidators(prikazIzmeneOsnovice: PrikazIzmenaOsnovice) {
    if (prikazIzmeneOsnovice === PrikazIzmenaOsnovice.FAKTURE_U_PERIODU) {
      this._setValidatorForPeriod();
    } else {
      this._setValidatorForPojedinacnaFaktura();
    }
  }

  //#region pojedinacna faktura

  private _setValidatorForPojedinacnaFaktura() {
    this._clearPeriods();
    (this.racunForm.controls.racunDto.get(
      'vezaniRacuniDto'
    ) as FormArray).setValidators([Validators.required]);
    this.racunForm
      .get('racunDto.datumNarudzbenice')
      .setValidators([Validators.required]);
    this._updateValueAndValidityForPojedinacna();
  }

  private _clearPojedinacnaFaktura() {
    this._clearValidatorForPojedinacnaFaktura();
    this._clearValueForPojedinacnaFaktura();
    this._updateValueAndValidityForPojedinacna();
  }

  private _clearValidatorForPojedinacnaFaktura() {
    (this.racunForm.controls.racunDto.get(
      'vezaniRacuniDto'
    ) as FormArray).clearValidators();
    this.racunForm.get('racunDto.datumNarudzbenice').clearValidators();
  }

  private _clearValueForPojedinacnaFaktura() {
    (this.racunForm.controls.racunDto.get(
      'vezaniRacuniDto'
    ) as FormArray).clear();
  }

  private _updateValueAndValidityForPojedinacna() {
    (this.racunForm.controls.racunDto.get(
      'vezaniRacuniDto'
    ) as FormArray).updateValueAndValidity();
    this.racunForm.get('racunDto.datumNarudzbenice').updateValueAndValidity();
  }

  //#endregion vezani racun

  //#region period
  private _setValidatorForPeriod() {
    this._clearPojedinacnaFaktura();
    this.racunForm.controls.periodOd.setValidators([Validators.required]);
    this.racunForm.controls.periodDo.setValidators([Validators.required]);
  }

  private _clearValidatorForPeriods() {
    this.racunForm.controls.periodOd.clearValidators();
    this.racunForm.controls.periodDo.clearValidators();
  }

  private _clearValuesForPeriods() {
    this.racunForm.controls.periodDo.patchValue(null);
    this.racunForm.controls.periodOd.patchValue(null);
  }

  private _upldateValueAndValidityForPeriods() {
    this.racunForm.controls.periodDo.updateValueAndValidity();
    this.racunForm.controls.periodOd.updateValueAndValidity();
  }

  private _clearPeriods() {
    this._clearValidatorForPeriods();
    this._clearValuesForPeriods();
    this._upldateValueAndValidityForPeriods();
  }

  //#endregion period

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
