export const apiBaseUrlClean = 'api.ekompanija.com';
export const kpsBaseUrlClean = 'kps.ekompanija.com';
export const mixpanelToken = 'dd7fd94469445ed8c8f3989df2d040c7';

export const environment = {
  production: true,
  apiBaseUrl: `https://${apiBaseUrlClean}`,
  kpsBaseUrl: `https://${kpsBaseUrlClean}`,
  version: '1.101.0',
  firebase: {
    apiKey: 'AIzaSyBIn6t7-jzO4ZLhsZo93dxWxoJifAxo2lQ',
    authDomain: 'ekompanija-4fde1.firebaseapp.com',
    projectId: 'ekompanija-4fde1',
    storageBucket: 'ekompanija-4fde1.appspot.com',
    messagingSenderId: '142074200795',
    appId: '1:142074200795:web:6dd97a511567e3249342fe',
    measurementId: 'G-7MRDEKRSVB',
  },
};
