import { GetDnevniIzvestajMaloprodajaPdfQuery, GetIzvestajIzlaznihDokumenataPdfQuery, GetIzvestajPoArtiklimaMaloprodajaPdfQuery, IDnevniIzvestajMaloprodajaFilter, IIzvestajiClient, IzvestajiClient } from './../../../../../../data-api/src/lib/eKompanija-api';
import { isEmptyCheck } from '@kodit/core/shared';
import { Component, OnInit } from '@angular/core';
import { MaloprodajaIzvestajService } from '../../maloprodaja-izvestaj.service';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import { VrstaIzvestajaMaloprodaja } from '../maloprodaja-page/maloprodaja-page-izvestaji.component';
import { Data } from '@angular/router';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';

@Component({
  selector: 'kodit-maloprodaja-table-izvestaji',
  templateUrl: './maloprodaja-table-izvestaji.component.html',
  styleUrls: ['./maloprodaja-table-izvestaji.component.scss']
})
export class MaloprodajaTableIzvestajiComponent implements OnInit {

  private _subs: Subscription = new Subscription();
  tableData : any;
  ukupnoPoArtiklima: number = 0;

  columns : Array<any> = [];
  columns2 : Array<any> = [];
  jePoArtiklima : boolean;
  prikazAvansProdaje: boolean;
  filter: FormGroupTypeSafe<IDnevniIzvestajMaloprodajaFilter> | undefined;

  tipIzvestaja : VrstaIzvestajaMaloprodaja = VrstaIzvestajaMaloprodaja.DNEVNI_IZVESTAJ;
  tip = VrstaIzvestajaMaloprodaja;

  datum : Data | undefined;
  datumOd: Data | undefined;
  datumDo: Data | undefined;
  kasir: string | undefined;
  nazivFajla : string;

  constructor(
    private _service: MaloprodajaIzvestajService,
    private _client : IzvestajiClient
  ) { }

  ngOnInit(): void {

    this._subs.add(
      this._service.getTipObservable.subscribe(tip => {
        this.tipIzvestaja = tip;
        if(this.tipIzvestaja == VrstaIzvestajaMaloprodaja.IZVESTAJ_PO_ARTIKLIMA){
          this.jePoArtiklima = true;
        }else {
          this.jePoArtiklima = false;
        }
        this.definisiImeFajla(this.tipIzvestaja);
      })
    );

    this._subs.add(
      this._service.getFilterObservable.subscribe(filter => {
        this.filter = filter;
        this.datum = filter?.controls.datum?.value;
        this.datumOd = filter?.controls.datumOd?.value;
        this.datumDo = filter?.controls.datumDo?.value;
      })
    );

    this._subs.add(
      this._service.getTableDataObservable.subscribe(data => {
        this.tableData = data;
        if(this.jePoArtiklima){
          this._izracunajUkupnoPoArtiklima();
        }

        this._definisiKolone();
      })
    );
  }

  private _izracunajUkupnoPoArtiklima() : void{
    if(this.tableData){
      this.ukupnoPoArtiklima = this.tableData.reduce((acc: any, item: { ukupno: any; }) => {
        return acc + (item.ukupno || 0);
      }, 0);
    }
  }

  private _definisiKolone(): void{
    if(this.jePoArtiklima){
      this.columns = [
        { field: 'sifra', header: 'Šifra' },
        { field: 'imeArtikla', header: 'Ime' },
        { field: 'cena', header: 'Cena' },
        { field: 'kolicina', header: 'Količina' },
        { field: 'ukupno', header: 'Ukupno' }
      ];
    }else{
      this.columns = [
        { field: 'tipPlacanja', header: 'Tip plaćanja' },
        { field: 'prodaja', header: 'Prodaja' },
        { field: 'refundacija', header: 'Refundacija' },
        { field: 'ukupno', header: 'Ukupno' }
      ];
      this.columns2 = [
        { field: 'oznaka', header: 'Oznaka' },
        { field: 'ime', header: 'Ime' },
        { field: 'stopa', header: 'Stopa' },
        { field: 'porez', header: 'Porez' }
      ];

      this.prikazAvansProdaje = isEmptyCheck(this.tableData?.avansPoNacinuPlacanja);
    }
  }

  exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    const table1 = !this.jePoArtiklima ? this.tableData.avansPoNacinuPlacanja : this.tableData;

    if(!this.prikazAvansProdaje || this.jePoArtiklima){
      const worksheet1 = XLSX.utils.aoa_to_sheet([
        this.columns.map(col => col.header),
        ...table1.map((row: { [x: string]: any; }) =>
          this.columns.map(col => row[col.field] || '')
        )
      ]);

      XLSX.utils.book_append_sheet(workbook, worksheet1, !this.jePoArtiklima ? 'Avans po načinu plaćanja' : 'Izveštaj po artiklima');
    }

    if(this.jePoArtiklima){
      XLSX.writeFile(workbook, `${this.nazivFajla}.xlsx`);
      return;
    }

    const table2 = this.tableData.prometPoNacinuPlacanja;
    const worksheet2 = XLSX.utils.aoa_to_sheet([
      this.columns.map(col => col.header),
      ...table2.map((row: { [x: string]: any; }) =>
        this.columns.map(col => row[col.field] || '')
      )
    ]);
    XLSX.utils.book_append_sheet(workbook, worksheet2, 'Promet po načinu plaćanja');

  const table3 = this.tableData.stanjeBlagajne;
    const worksheet3 = XLSX.utils.aoa_to_sheet([
      this.columns.map(col => col.header),
      ...table3.map((row: { [x: string]: any; }) =>
        this.columns.map(col => row[col.field] || '')
      )
    ]);
    XLSX.utils.book_append_sheet(workbook, worksheet3, 'Stanje blagajne');

    const table4 = this.tableData.prometPoStopama;
    const worksheet4 = XLSX.utils.aoa_to_sheet([
      this.columns2.map(col => col.header),
      ...table4.map((row: { [x: string]: any; }) =>
        this.columns2.map(col => row[col.field] || '')
      )
    ]);
    XLSX.utils.book_append_sheet(workbook, worksheet4, 'Promet po stopama');

    XLSX.writeFile(workbook, `${this.nazivFajla}.xlsx`);
  };

  private definisiImeFajla(tip: VrstaIzvestajaMaloprodaja) {
    switch (tip) {
      case VrstaIzvestajaMaloprodaja.DNEVNI_IZVESTAJ:
        this.nazivFajla = "dnevni-izvestaj";
        break;
      case VrstaIzvestajaMaloprodaja.PERIODICNI_IZVESTAJ:
        this.nazivFajla = "periodicni-izvestaj";
        break;
      case VrstaIzvestajaMaloprodaja.PERIODICNI_KASIR:
        this.nazivFajla = "periodicni-izvestaj-kasir";
        break;
      default:
        this.nazivFajla = "izvestaj-prodatih-artikala";
        break;
    }
  }

  public exportToPDF() : void{

    var data;

    if(!this.jePoArtiklima){
      data = this._service.mapToPDFData(this.tableData, this.filter);
      this._subs.add(
        this._client
          .getIzvestajPoNacinuPlacanjaPdf(data as GetDnevniIzvestajMaloprodajaPdfQuery)
          .subscribe((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.download =  `${this.nazivFajla}.pdf` ;
            link.href = window.URL.createObjectURL(blob);
            document.body.appendChild(link);

            link.click();
            document.body.removeChild(link);
          })
      );

    }else{
      data = this._service.mapToPDFDataArtikli(this.tableData, this.filter);
      this._subs.add(
        this._client
          .getIzvestajPoArtiklimaPdf(data as GetIzvestajPoArtiklimaMaloprodajaPdfQuery)
          .subscribe((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.download =  `${this.nazivFajla}.pdf` ;
            link.href = window.URL.createObjectURL(blob);
            document.body.appendChild(link);

            link.click();
            document.body.removeChild(link);
          })
      );
    }

  }

}


