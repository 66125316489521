import { Injectable } from "@angular/core";
import { DnevniIzvestajMaloprodajaFilter, IDnevniIzvestajMaloprodajaFilter, IzvestajPoArtiklimaTableDto, MaloprodajaIzvestajDto } from "@kodit/core/data-api";
import { FormGroupTypeSafe } from "angular-typesafe-reactive-forms-helper";
import { BehaviorSubject, Observable } from "rxjs";
import { VrstaIzvestajaMaloprodaja } from "./maloprodaja/maloprodaja-page/maloprodaja-page-izvestaji.component";

@Injectable({
  providedIn: 'root',
})

export class MaloprodajaIzvestajService {

  tableData$ = new BehaviorSubject<any>(null);
  filter$ = new BehaviorSubject<FilterForm>(null);
  tip$ = new BehaviorSubject<VrstaIzvestajaMaloprodaja>(VrstaIzvestajaMaloprodaja.DNEVNI_IZVESTAJ);

  set setTableData(tableData: any){
    this.tableData$.next(tableData);
  }

  get getTableData(): boolean {
  return this.tableData$.getValue();
  }

  get getTableDataObservable(): Observable<any> {
    return this.tableData$.asObservable();
  }

  set setFilter(model: FilterForm) {
    this.filter$.next(model);
  }

  get getFilterObject(): FilterForm {
    return this.filter$.getValue();
  }

  get getFilterObservable(): Observable<FilterForm> {
    return this.filter$.asObservable();
  }

  set setTip(tip: any){
    this.tip$.next(tip);
  }

  get getTip(): VrstaIzvestajaMaloprodaja {
    return this.tip$.getValue();
  }

  get getTipObservable(): Observable<VrstaIzvestajaMaloprodaja> {
    return this.tip$.asObservable();
  }

  public mapToPDFData(data: any, form: FormGroupTypeSafe<IDnevniIzvestajMaloprodajaFilter>): any {

    const mappedData =  {
      maloprodajaIzvestajDto: {
          nazivPoreskogObveznika: data.nazivPoreskogObveznika,
          pib: data.pib,
          adresaObjekta: data.adresaObjekta,
          grad: data.grad,
          prometProdaja: data.prometProdaja,
          prometRefundacija: data.prometRefundacija,
          prometStorno: data.prometStorno,
          prometUkupno: data.prometUkupno,
          avansProdaja: data.avansProdaja,
          avansRealizacija: data.avansRealizacija,
          avansRefundacija: data.avansRefundacija,
          avansUkupno: data.avansUkupno,
          prometPoNacinuPlacanja: data.prometPoNacinuPlacanja?.map((doc) => ({
              tipPlacanja: doc.tipPlacanja,
              prodaja: doc.prodaja,
              refundacija: doc.refundacija,
              ukupno: doc.ukupno,
            })),
          avansPoNacinuPlacanja: data.avansPoNacinuPlacanja?.map((doc) => ({
            tipPlacanja: doc.tipPlacanja,
            prodaja: doc.prodaja,
            refundacija: doc.refundacija,
            ukupno: doc.ukupno,
          })),
          stanjeBlagajne: data.stanjeBlagajne?.map((doc) => ({
            tipPlacanja: doc.tipPlacanja,
            prodaja: doc.prodaja,
            refundacija: doc.refundacija,
            ukupno: doc.ukupno,
          })),
          ukupnoPrometPoNacinuPlacanja: data.ukupnoPrometPoNacinuPlacanja,
          ukupnoAvansPoNacinuPlacanja: data.ukupnoAvansPoNacinuPlacanja,
          ukupnoStanjeBlagajne: data.ukupnoStanjeBlagajne,
          prometPoStopama: data.prometPoStopama?.map((doc) => ({
            oznaka: doc.oznaka,
            ime: doc.ime,
            stopa: doc.stopa,
            porez: doc.porez,
          })),
          ukupnoPorezPoStopama: data.ukupnoPorezPoStopama
        },
        filter : {
          datum : form?.controls.datum?.value,
          datumOd : form?.controls.datumOd?.value,
          datumDo : form?.controls.datumDo?.value,
          kasirId : form?.controls.kasirId?.value
        }
    };

    return mappedData;
  }

  public mapToPDFDataArtikli(data: IzvestajPoArtiklimaTableDto[], form: FormGroupTypeSafe<IDnevniIzvestajMaloprodajaFilter>): any {

    const mappedData =  {
      izvestajPoArtiklimaDto: data.map((doc) => ({
          sifra: doc.sifra,
          imeArtikla: doc.imeArtikla,
          cena: doc.cena,
          kolicina: doc.kolicina,
          ukupno: doc.ukupno
        } )),
      datumOd: form.controls.datumOd?.value,
      datumDo: form.controls.datumDo?.value
    }

    return mappedData;
  }


}
export declare type FilterForm = FormGroupTypeSafe<IDnevniIzvestajMaloprodajaFilter> | null;
