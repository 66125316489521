import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** Moduli */
import { CoreSharedModule } from 'libs/core/shared/src';
import { IzvestajRoutingModule } from './izvestaji-routing';

/** Components */
import { IzvestajCardComponent } from './ui/izvestaj-card/izvestaj-card.component';
import { KpoKnjigaPageComponent } from './kpo-knjiga/kpo-knjiga-page/kpo-knjiga-page.component';
import { KpoKnjigaTableComponent } from './kpo-knjiga/kpo-knjiga-table/kpo-knjiga-table.component';
import { KpoKnjigaPdfComponent } from './kpo-knjiga/kpo-knjiga-pdf/kpo-knjiga-pdf.component';
import { IzvestajPageComponent } from './izvestaj-page/izvestaj-page.component';
import { IzvestajFiskalnaKasaPageComponent } from './fiskalni-racun/izvestaj-fiskalna-kasa-page/izvestaj-fiskalna-kasa-page.component';
import { SumPaymentsPipe } from './fiskalni-racun/izvestaj-fiskalna-kasa-page/sum-payments.pipe';
import { SumTaxItemsPipe } from './fiskalni-racun/izvestaj-fiskalna-kasa-page/sum-tax-items.pipe';
import { IosIzvestajPageComponent } from './ios-izvestaj/ios-izvestaj-page/ios-izvestaj-page.component';
import { IosIzvestajTableComponent } from './ios-izvestaj/ios-izvestaj-table/ios-izvestaj-table.component';
import { IosIzvestajPdfComponent } from './ios-izvestaj/ios-izvestaj-pdf/ios-izvestaj-pdf.component';
import { MaloprodajaPageIzvestajiComponent } from './maloprodaja/maloprodaja-page/maloprodaja-page-izvestaji.component';
import { MaloprodajaFilterIzvestajiComponent } from './maloprodaja/filter-izvestaji/maloprodaja-filter-izvestaji.component';
import { MaloprodajaTableIzvestajiComponent } from './maloprodaja/maloprodaja-table/maloprodaja-table-izvestaji.component';
import { VeleprodajaPageIzvestajiComponent } from './veleprodaja/veleprodaja-page-izvestaji/veleprodaja-page-izvestaji.component';
import { VeleprodajaFilterIzvestajiComponent } from './veleprodaja/veleprodaja-filter-izvestaji/veleprodaja-filter-izvestaji.component';
import { VeleprodajaTableIzvestajiComponent } from './veleprodaja/veleprodaja-table-izvestaji/veleprodaja-table-izvestaji.component';

@NgModule({
  imports: [CommonModule, CoreSharedModule, IzvestajRoutingModule],
  declarations: [
    SumPaymentsPipe,
    SumTaxItemsPipe,
    IzvestajPageComponent,
    IzvestajCardComponent,
    KpoKnjigaPageComponent,
    KpoKnjigaTableComponent,
    KpoKnjigaPdfComponent,
    IzvestajFiskalnaKasaPageComponent,
    IosIzvestajPageComponent,
    IosIzvestajTableComponent,
    IosIzvestajPdfComponent,
    MaloprodajaPageIzvestajiComponent,
    MaloprodajaFilterIzvestajiComponent,
    MaloprodajaTableIzvestajiComponent,
    VeleprodajaPageIzvestajiComponent,
    VeleprodajaFilterIzvestajiComponent,
    VeleprodajaTableIzvestajiComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [SumPaymentsPipe, SumTaxItemsPipe],
})
export class CoreModuliIzvestajModule {}
