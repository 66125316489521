import { Component, OnDestroy, OnInit } from '@angular/core';
import { IRacunDto } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { TEZINA_PALETE } from '@kodit/core/shared';
import { TEZINA_RAMA } from '@kodit/core/shared';
import { Subscription } from 'rxjs';
import { RacunService } from '../../..';

@Component({
  selector: 'kodit-sekcija-za-racunanje-tezine',
  templateUrl: './sekcija-za-racunanje-tezine.component.html',
  styleUrls: ['./sekcija-za-racunanje-tezine.component.scss'],
})
export class SekcijaZaRacunanjeTezineComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  racunForm: FormGroupTypeSafe<IRacunDto>;

  constructor(private _racunService: RacunService) {}

  ngOnInit(): void {
    this.racunForm = this._racunService.getRacunFormObject;
    this._racunService.setNetoTezina = this.racunForm.controls.netoTezina.value;

    this._subs.add(
      this.racunForm.controls.brojPaleta.valueChanges.subscribe((res) => {
        this.racunForm.controls.brutoTezina?.patchValue(
          this.racunForm.controls.netoTezina.value +
            res * TEZINA_PALETE +
            this.racunForm.controls.brojRamova.value * TEZINA_RAMA
        );
      })
    );

    this._subs.add(
      this.racunForm.controls.brojRamova.valueChanges.subscribe((res) => {
        this.racunForm.controls.brutoTezina?.patchValue(
          this.racunForm.controls.netoTezina.value +
            res * TEZINA_RAMA +
            this.racunForm.controls.brojPaleta.value * TEZINA_PALETE
        );
      })
    );

    this._subs.add(
      this.racunForm.controls.netoTezina.valueChanges.subscribe((res) => {
        this.racunForm.controls.brutoTezina?.patchValue(
          res +
            this.racunForm.controls.brojPaleta.value * TEZINA_PALETE +
            this.racunForm.controls.brojRamova.value * TEZINA_RAMA
        );
      })
    );

    this._subs.add(
      this._racunService.getNetoTezina.subscribe((res) => {
        this.racunForm.controls.netoTezina?.patchValue(res ?? 0);
        this.racunForm.controls.netoTezina?.updateValueAndValidity();
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this._racunService.resetNetoTezina(0);
  }
}
