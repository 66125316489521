<p-sidebar
  #ProfilSidebar
  [(visible)]="showSidebar"
  position="right"
  [(showCloseIcon)]="showcloseIcon"
  class="p-sidebar-md"
  (onHide)="onHide()"
  (onShow)="onShow()"
>
  <div class="header p-d-flex p-jc-between">
    <span
      style="
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: rgb(46, 57, 75);
      "
    >
      Profil
    </span>
    <a
      (click)="onHide()"
      class="p-d-flex p-ai-center p-jc-center close"
      tabindex="0"
    >
      <img src="assets/icons/x.svg" width="24" height="24" alt="user-image" />
    </a>
  </div>

  <div class="_body">
    <div class="p-d-flex p-jc-between">
      <div class="p-d-flex p-ai-center">
        <a
          class="verzija"
          style="cursor: pointer"
          (click)="openVerzijaSoftveraDijalog()"
          target="_blank"
          >Informacije o softveru</a
        >
      </div>
      <p-button
        (click)="logOff()"
        label="Odjavite se"
        styleClass="p-button-danger"
      ></p-button>
    </div>

    <kodit-divider></kodit-divider>
    <kodit-srednji-kurs-za-valute></kodit-srednji-kurs-za-valute>

    <p-scrollPanel
      class="layout-menu-container"
      [style]="{ width: '100%', height: 'calc(100vh - 200px)' }"
    >
      <div>
        <kodit-divider></kodit-divider>
        <section class="intro_cards p-mt-2">
          <div class="intro_cards_container p-d-flex p-flex-column gap-12">
            <!-- Uvod - dokumentacija -->
            <div class="intro_card_container">
              <div class="card align_center">
                <a
                  data-clog-click="true"
                  data-clog-ui-component="hc_primary_card"
                  data-clog-ui-element="link_getting_started"
                  href="https://ekompanija.gitbook.io/ekompanija-or-baza-znanja/uvod-u-ekompanija-aplikaciju/korisnicki-interfejs"
                  target="_blank"
                  class="tile p-d-flex p-ai-center p-jc-between"
                  title="Getting Started"
                >
                  <div class="p-d-flex">
                    <div class="_icon-wrapper">
                      <img
                        src="assets/icons/book-bookmark.svg"
                        width="40"
                        height="40"
                        alt="user-image"
                      />
                    </div>

                    <div class="_details p-ml-3">
                      <h2 class="card_title">Uvod - Dokumentacija</h2>
                      <p class="card_description">
                        Kako početi sa korišćenjem aplikacije
                      </p>
                    </div>
                  </div>

                  <div class="arrow">
                    <img
                      src="assets/icons/arrow-right.svg"
                      width="24"
                      height="24"
                      alt="user-image"
                    />
                  </div>
                </a>
              </div>
            </div>

            <!-- Video uputstva -->
            <div class="intro_card_container">
              <div class="card align_center">
                <a
                  data-clog-click="true"
                  data-clog-ui-component="hc_primary_card"
                  data-clog-ui-element="link_getting_started"
                  href="https://www.youtube.com/@ekompanija8940/videos"
                  target="_blank"
                  class="tile p-d-flex p-ai-center p-jc-between"
                  title="Getting Started"
                >
                  <div class="p-d-flex">
                    <div class="_icon-wrapper">
                      <img
                        src="assets/icons/video-library.svg"
                        width="40"
                        height="40"
                        alt="user-image"
                      />
                    </div>

                    <div class="_details p-ml-3">
                      <h2 class="card_title">Video uputstva</h2>
                      <p class="card_description">
                        Ovde možete pronaći video uputstva.
                      </p>
                    </div>
                  </div>
                  <div class="arrow">
                    <img
                      src="assets/icons/arrow-right.svg"
                      width="24"
                      height="24"
                      alt="user-image"
                    />
                  </div>
                </a>
              </div>
            </div>

            <!-- Cesta pitanja -->
            <!-- <div class="intro_card_container">
              <div class="card align_center">
                <a
                  data-clog-click="true"
                  data-clog-ui-component="hc_primary_card"
                  data-clog-ui-element="link_getting_started"
                  href="https://ekompanija.gitbook.io/ekompanija-or-baza-znanja/cesto-postavljana-pitanja/opsta"
                  target="_blank"
                  class="tile p-d-flex p-ai-center p-jc-between"
                  title="Getting Started"
                >
                  <div class="p-d-flex">
                    <div class="_icon-wrapper">
                      <img
                        src="assets/icons/question-square.svg"
                        width="40"
                        height="40"
                        alt="user-image"
                      />
                    </div>

                    <div class="_details p-ml-3">
                      <h2 class="card_title">Česta pitanja</h2>
                      <p class="card_description">Često postavljana pitanja</p>
                    </div>
                  </div>

                  <div class="arrow">
                    <img
                      src="assets/icons/arrow-right.svg"
                      width="24"
                      height="24"
                      alt="user-image"
                    />
                  </div>
                </a>
              </div>
            </div> -->

            <!-- Remote acces -->
            <div class="intro_card_container">
              <div class="card align_center">
                <a
                  data-clog-click="true"
                  data-clog-ui-component="hc_primary_card"
                  data-clog-ui-element="link_getting_started"
                  href="https://ekompanija.gitbook.io/ekompanija-or-baza-znanja/briga-o-korisniima/podrska-vezom-na-daljinu"
                  target="_blank"
                  class="tile p-d-flex p-ai-center p-jc-between"
                  title="Getting Started"
                >
                  <div class="p-d-flex">
                    <div class="_icon-wrapper">
                      <img
                        src="assets/icons/cloud-download.svg"
                        width="40"
                        height="40"
                        alt="user-image"
                      />
                    </div>

                    <div class="_details p-ml-3">
                      <h2 class="card_title">Programi za vezu na daljinu</h2>
                      <p class="card_description">
                        Link za preuzimanje AnyDesk programa
                      </p>
                    </div>
                  </div>

                  <div class="arrow">
                    <img
                      src="assets/icons/arrow-right.svg"
                      width="24"
                      height="24"
                      alt="user-image"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <kodit-divider></kodit-divider>
        <span class="_title_secondary">Potrebna Vam je pomoć?</span>
        <div class="p-mt-3">
          <div class="p-d-flex podesavanja-card-item" (click)="openKreirajTiketDialog('pitanje')">
            <div class="p-d-flex p-ai-center">
              <div class="_image">
                <img
                  src="assets/icons/mailbox.svg"
                  width="24"
                  height="24"
                  alt="user-image"
                />
              </div>

              <div class="p-d-flex p-jc-between _title-section p-ml-3">
                <div class="_title">Pošaljite nam e-mail</div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-mt-2">
          <div class="p-d-flex podesavanja-card-item" (click)="openKreirajTiketDialog('problem')">
            <div class="p-d-flex p-ai-center">
              <div class="_image">
                <img
                  src="assets/icons/bug-minimalistic.svg"
                  width="24"
                  height="24"
                  alt="user-image"
                />
              </div>

              <div class="p-d-flex p-jc-between _title-section p-ml-3">
                <div class="_title">Prijavite problem</div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-mt-2">
          <div class="p-d-flex podesavanja-card-item">
            <div class="p-d-flex p-ai-center">
              <div class="_image">
                <img
                  src="assets/icons/incoming-call-rounded.svg"
                  width="24"
                  height="24"
                  alt="user-image"
                />
              </div>

              <div class="p-d-flex gap-2 p-ml-3 p-flex-row p-ai-center gap-12">
                  <div>Podrška</div>
                <div class="p-d-flex p-flex-column">
                  <div>+381 60 416 8013</div>
                  <div>+381 60 416 8003</div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- <div class="p-mt-2">
          <div class="p-d-flex podesavanja-card-item" style="cursor: default">
            <div class="p-d-flex p-ai-center">
              <div class="_image">
                <img
                  src="assets/icons/incoming-call-rounded.svg"
                  width="24"
                  height="24"
                  alt="user-image"
                />
              </div>
              <div class="p-d-flex p-jc-between _title-section p-ml-3">
                <div class="_title">Tehnička podrška - 1: +381 60 416 8013</div>

              </div>
              <div class="p-d-flex p-jc-between _title-section p-ml-3">
                <div class="_title">Pozovite nas (ponedeljak - petetak)</div>
                <div class="_subtitle p-mt-2">
                  Tehnička podrška - 1: +381 60 416 8013
                </div>
                <div class="_subtitle p-mt-1">
                  Tehnička podrška - 2: +381 60 416 8003
                </div>
                <div class="_subtitle p-mt-1">Prodaja: +381 60 66 99 361</div>
                <div class="_subtitle p-mt-1">Finansije: +381 60 66 99 361</div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </p-scrollPanel>
  </div>
</p-sidebar>
