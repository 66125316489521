import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { GetDnevniIzvestajMaloprodajaQuery, GetIzvestajPoArtiklimaMaloprodajaQuery, IDnevniIzvestajMaloprodajaFilter, IIzvestajiClient, IKorisnikAutocompleteDto, IMaloprodajaIzvestajDto, IzvestajiClient } from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { Store } from '@ngrx/store';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { VrstaIzvestajaMaloprodaja } from '../maloprodaja-page/maloprodaja-page-izvestaji.component';
import { MaloprodajaIzvestajService } from '../../maloprodaja-izvestaj.service';
import { AlertService, SharedService } from '@kodit/core/services';

@Component({
  selector: 'kodit-maloprodaja-filter-izvestaji',
  templateUrl: './maloprodaja-filter-izvestaji.component.html',
  styleUrls: ['./maloprodaja-filter-izvestaji.component.scss']
})
export class MaloprodajaFilterIzvestajiComponent implements OnInit {

  @Input() tipIzvestaja: VrstaIzvestajaMaloprodaja;
  private _subs: Subscription = new Subscription();
  /**Props */
  filterData!: IDnevniIzvestajMaloprodajaFilter;
  form!: FormGroupTypeSafe<IDnevniIzvestajMaloprodajaFilter>;
  tableData : any;
  tip = VrstaIzvestajaMaloprodaja;
  kasir : string;


  constructor(
    private _fb: FormBuilderTypeSafe,
    private _formHelper: FormHelper,
    private _client: IzvestajiClient,
    private _service: MaloprodajaIzvestajService,
    private _alertService: AlertService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.tipIzvestaja = VrstaIzvestajaMaloprodaja.DNEVNI_IZVESTAJ;
    this._service.setTip =this.tipIzvestaja;
    this.form = this.getForm(this.filterData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tipIzvestaja = changes['tipIzvestaja'].currentValue.value;
    this._service.setTip = this.tipIzvestaja;
    this._resetValidators();
    if(this.form){
      this._resetujFilter();
    }
    this._service.setTableData = null;
  }

  public primeniFilter(): void{

    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    var filter = {
      filteri: {
        datum: this.form.value.datum,
        datumOd: this.form.value.datumOd,
        datumDo: this.form.value.datumDo,
        kasirId: this.form.value.kasirId
      }
    }

    this._service.setFilter = this.form;

    if(this.tipIzvestaja == VrstaIzvestajaMaloprodaja.IZVESTAJ_PO_ARTIKLIMA){
      this._sharedService.displayLoadingScreen(true, 'Pribavljanje podataka...');
      this._subs.add(
        this._client
          .getIzvestajPoArtiklimaMaloprodaja(filter as GetIzvestajPoArtiklimaMaloprodajaQuery)
          .subscribe((result) => {
            if(result.succeeded){
              this._service.setTableData = result.data;
            }else {
              this._service.setTableData = null;
              this._alertService.addWarnMsg("Za primenjeni filter nema podataka!")
            }
          })
      );
    }else{
      this._sharedService.displayLoadingScreen(true, 'Pribavljanje podataka...');
      this._subs.add(
        this._client
          .getDnevniIzvestajMaloprodaja(filter as GetDnevniIzvestajMaloprodajaQuery)
          .subscribe((result) => {
            if(result.succeeded){
              this._service.setTableData = result.data;
            }else {
              this._service.setTableData = null;
              this._alertService.addWarnMsg("Za primenjeni filter nema podataka!")
            }
          })
      );
    }
  }

  handleKorisnikChange(korisnik: IKorisnikAutocompleteDto) {
    this.form.controls.kasirId?.setValue(
      korisnik.id
    );
  }

  private _resetujFilter(): void {
    this.form.reset();
    if(this.tipIzvestaja == VrstaIzvestajaMaloprodaja.DNEVNI_IZVESTAJ){
      this.form.controls.datum?.setValue(new Date());
    }
  }

  private _resetValidators(): void{
    this.form?.controls.datum?.clearValidators();
    this.form?.controls.datumOd?.clearValidators();
    this.form?.controls.datumDo?.clearValidators();

    if(this.tipIzvestaja == VrstaIzvestajaMaloprodaja.DNEVNI_IZVESTAJ){
      this.form.controls.datum?.addValidators([Validators.required]);
    }else if(this.tipIzvestaja == VrstaIzvestajaMaloprodaja.PERIODICNI_IZVESTAJ
      || this.tipIzvestaja == VrstaIzvestajaMaloprodaja.IZVESTAJ_PO_ARTIKLIMA
      || this.tipIzvestaja == VrstaIzvestajaMaloprodaja.PERIODICNI_KASIR){
        this.form.controls.datumOd?.addValidators([Validators.required]);
      this.form.controls.datumDo?.addValidators([Validators.required]);
      }
    this.form?.updateValueAndValidity();
  }

  public onClear() {
    this.form?.controls.kasirId?.setValue(null);
  }

  private getForm(
    model?: IDnevniIzvestajMaloprodajaFilter
  ): FormGroupTypeSafe<IDnevniIzvestajMaloprodajaFilter> {
    return this._fb.group<IDnevniIzvestajMaloprodajaFilter>({
      datum: new FormControl(model?.datum! ?? new Date(),
      {
        validators: [Validators.required]
      }
      ),
      datumOd:  new FormControl(model?.datumOd!),
      datumDo:  new FormControl(model?.datumDo!),
      kasirId: new FormControl(model?.kasirId!)
    });
  }
}
