<div *ngIf="tableData">
  <div style="display: flex; justify-content: flex-end; gap: 10px;" class="button-container">
      <button
        pButton
          class="export-button"
          type="button"
          label="Izvezi u Excel"
          icon="fas fa-file-excel"
          (click)="exportToExcel()"
      ></button>
      <button
          pButton
          class="export-button pdf-button"
          type="button"
          label="Izvezi u PDF"
          icon="fa-solid fa-file-pdf"
          (click)="exportToPDF()"
      ></button>
  </div>
  <div id="printable-content">
    <div class="printable-content-zaglavlje">
          <div class="header">
            <div class="title" *ngIf="form.controls.tipIzvestajaVeleprodaja?.value == tip.PO_ARTIKLIMA">Lista izlaznih dokumenata po artiklima</div>
            <div class="title" *ngIf="form.controls.tipIzvestajaVeleprodaja?.value == tip.PO_PARTNERIMA">Lista izlaznih dokumenata po partnerima</div>
            <div class="subtitle">
              <div>Period od {{ form.controls.datumOd?.value | date:'dd.MM.yyyy.'}} do {{ form.controls.datumDo?.value | date:'dd.MM.yyyy.'}}</div>
            </div>
        </div>
    </div>

      <ng-container *ngFor="let item of tableData">
        <kodit-field [fieldLabel]="item.nazivGrupacije" fieldLabelFor="prometPoNacinuPlacanja">
          <p-table [value]="item.izlazniDokumentiIzvestajDto" class="custom-table">
              <ng-template pTemplate="header">
                  <tr>
                    <th style="text-align: left;" *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value == podtip.KOMPLETAN">Broj</th>
                    <th *ngIf="form.controls.tipIzvestajaVeleprodaja?.value == tip.PO_PARTNERIMA
                          || form.controls.podtipIzvestajaVeleprodaja?.value == podtip.SUMIRAN " style="width: 20%; text-align: left;">
                       Naziv robe
                    </th>
                    <th *ngIf="form.controls.tipIzvestajaVeleprodaja?.value == tip.PO_ARTIKLIMA
                        && form.controls.podtipIzvestajaVeleprodaja?.value == podtip.KOMPLETAN " style="width: 20%; text-align: left;">
                        Kupac
                    </th>
                    <th *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value == podtip.KOMPLETAN">Datum</th>
                    <th style="width: 3%;">Jed. mere</th>
                    <th style="width: 3%;">Količina</th>
                    <th style="text-align: right;" *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value == podtip.KOMPLETAN">Cena bez PDV-a</th>
                    <th style="text-align: right;">Vrednost robe</th>
                    <th style="text-align: right;">Rabat</th>
                    <th style="text-align: right;">PDV</th>
                    <th style="text-align: right;">Fakturisana vrednost</th>

              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                  <tr>
                      <td style="text-align: left;" *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value == podtip.KOMPLETAN">{{ rowData.broj }}</td>
                      <td style="text-align: left;">{{ rowData.nazivPodgrupacije}}</td>
                      <td *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value == podtip.KOMPLETAN">{{ rowData.datum | date:'dd.MM.yyyy.' }}</td>
                      <td>{{ rowData.jedinicaMere }}</td>
                      <td>{{ rowData.kolicina }}</td>
                      <td style="text-align: right;" *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value == podtip.KOMPLETAN">{{ rowData.cena | number:'1.2-4'}}</td>
                      <td style="text-align: right;">{{ rowData.vrednostRobe | number:'1.2-4'}}</td>
                      <td style="text-align: right;">{{ rowData.rabat | number:'1.2-4'}}</td>
                      <td style="text-align: right;">{{ rowData.pdv | number:'1.2-4'}}</td>
                      <td style="text-align: right;">{{ rowData.fakturisanaVrednost | number:'1.2-4'}}</td>
                  </tr>
              </ng-template>
              <ng-template pTemplate="footer" class="footer">
                <tr>
                  <td style="text-align: left;" colspan="4" *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value == podtip.KOMPLETAN">Ukupno:</td>
                  <td style="text-align: left;" *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value == podtip.SUMIRAN">Ukupno:</td>
                  <td>
                    <p *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value == podtip.KOMPLETAN"> {{ item.ukupnoKolicina }}</p>
                  </td>
                  <td *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value == podtip.SUMIRAN">{{ item.ukupnoKolicina }}</td>
                  <td *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value == podtip.KOMPLETAN"></td>
                  <td style="text-align: right;">{{ item.ukupnoVrednostRobe | number:'1.2-4'}}</td>
                  <td style="text-align: right;">{{ item.ukupnoRabat | number:'1.2-4'}}</td>
                  <td style="text-align: right;">{{ item.ukupnoPdv | number:'1.2-4'}}</td>
                  <td style="text-align: right;">{{ item.ukupnoFakturisanaVrednost | number:'1.2-4'}}</td>
                </tr>
              </ng-template>
          </p-table>
        </kodit-field>
      </ng-container>


  </div>
</div>
<div
  *ngIf="!tableData"
  class="p-d-flex p-flex-md-column p-ai-center flex-gap-1 empty-folder"
>
<div>
  <svg
    width="120"
    height="108"
    viewBox="0 0 120 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3432_5939)">
      <path
        d="M61.4571 0.234773L57.5525 1.24169L9.4472 13.6471L5.54261 14.654C3.64737 15.145 2.02429 16.3678 1.02946 18.054C0.0346361 19.7402 -0.250699 21.7522 0.236059 23.6486L17.1672 89.3038C17.6582 91.199 18.881 92.8221 20.5672 93.8169C22.2534 94.8118 24.2654 95.0971 26.1618 94.6103L26.1718 94.6077L82.0662 80.1937L82.0763 80.1911C83.9715 79.7001 85.5946 78.4773 86.5894 76.7911C87.5842 75.1049 87.8696 73.0929 87.3828 71.1966L70.4517 5.54136C69.9606 3.64612 68.7379 2.02303 67.0517 1.0282C65.3654 0.03336 63.3535 -0.251981 61.4571 0.234773Z"
        fill="#F2F2F2"
      />
      <path
        d="M61.9877 2.29324L57.363 3.48586L10.6978 15.5199L6.07317 16.7125C4.72352 17.0621 3.56768 17.9329 2.85924 19.1337C2.15079 20.3345 1.9476 21.7673 2.29423 23.1177L19.2254 88.7729C19.5751 90.1226 20.4458 91.2784 21.6466 91.9868C22.8474 92.6953 24.2802 92.8985 25.6306 92.5518L25.6407 92.5492L81.535 78.1352L81.5452 78.1326C82.8948 77.7829 84.0506 76.9122 84.759 75.7114C85.4675 74.5106 85.6707 73.0778 85.324 71.7274L68.3929 6.07218C68.0432 4.72255 67.1725 3.56672 65.9717 2.85827C64.7709 2.14983 63.3382 1.94662 61.9877 2.29324Z"
        fill="white"
      />
      <path
        d="M62.0073 2.3135L57.3825 3.50612L10.7173 15.5401L6.0927 16.7327C4.74305 17.0824 3.58721 17.9532 2.87877 19.154C2.17032 20.3548 1.96713 21.7875 2.31376 23.138L19.2449 88.7932C19.5946 90.1428 20.4653 91.2987 21.6661 92.0071C22.8669 92.7155 24.2997 92.9187 25.6501 92.5721L25.6602 92.5695L81.5546 78.1555L81.5647 78.1528C82.9143 77.8032 84.0702 76.9324 84.7786 75.7316C85.487 74.5308 85.6902 73.0981 85.3436 71.7477L68.4124 6.09245C68.0628 4.74281 67.192 3.58698 65.9912 2.87854C64.7905 2.17009 63.3577 1.96689 62.0073 2.3135Z"
        fill="white"
      />
      <path
        d="M58.496 23.5541L30.2204 30.8458C29.9046 30.9273 29.5693 30.8799 29.2884 30.7142C29.0076 30.5485 28.804 30.2779 28.7226 29.9621C28.6411 29.6463 28.6885 29.3111 28.8542 29.0302C29.0199 28.7493 29.2904 28.5457 29.6062 28.4643L57.8818 21.1726C58.1975 21.0915 58.5325 21.139 58.8132 21.3048C59.0938 21.4705 59.2972 21.7409 59.3786 22.0566C59.46 22.3722 59.4127 22.7072 59.2472 22.988C59.0817 23.2688 58.8115 23.4724 58.496 23.5541Z"
        fill="#F2F2F2"
      />
      <path
        d="M64.443 26.3063L31.2555 34.8647C30.9397 34.9461 30.6045 34.8988 30.3236 34.733C30.0427 34.5673 29.8392 34.2968 29.7577 33.981C29.6763 33.6652 29.7236 33.3299 29.8893 33.049C30.0551 32.7681 30.3256 32.5646 30.6414 32.4831L63.8289 23.9248C64.1447 23.8433 64.4799 23.8907 64.7608 24.0564C65.0417 24.2221 65.2453 24.4926 65.3267 24.8084C65.4081 25.1243 65.3608 25.4595 65.1951 25.7404C65.0294 26.0213 64.7588 26.2248 64.443 26.3063Z"
        fill="#F2F2F2"
      />
      <path
        d="M63.1405 41.5644L34.8649 48.8561C34.7085 48.8964 34.5458 48.9056 34.3859 48.883C34.226 48.8604 34.0721 48.8065 33.933 48.7245C33.7939 48.6424 33.6723 48.5338 33.5752 48.4047C33.4781 48.2757 33.4074 48.1288 33.3671 47.9724C33.3268 47.816 33.3176 47.6533 33.3402 47.4934C33.3628 47.3335 33.4167 47.1796 33.4987 47.0405C33.5808 46.9014 33.6894 46.7798 33.8185 46.6827C33.9475 46.5856 34.0944 46.5149 34.2508 46.4746L62.5263 39.1829C62.6827 39.1426 62.8455 39.1334 63.0054 39.156C63.1653 39.1786 63.3192 39.2325 63.4583 39.3145C63.5974 39.3966 63.7189 39.5052 63.816 39.6343C63.9131 39.7633 63.9839 39.9102 64.0242 40.0666C64.0645 40.2229 64.0736 40.3857 64.0511 40.5456C64.0285 40.7055 63.9746 40.8594 63.8926 40.9985C63.8105 41.1376 63.7018 41.2592 63.5728 41.3562C63.4438 41.4533 63.2969 41.5241 63.1405 41.5644Z"
        fill="#F2F2F2"
      />
      <path
        d="M69.0875 44.3165L35.9001 52.8749C35.7437 52.9152 35.5809 52.9244 35.421 52.9018C35.2611 52.8792 35.1072 52.8253 34.9681 52.7433C34.8291 52.6612 34.7075 52.5526 34.6104 52.4235C34.5133 52.2945 34.4426 52.1476 34.4022 51.9912C34.3619 51.8349 34.3528 51.6721 34.3754 51.5122C34.398 51.3523 34.4518 51.1984 34.5339 51.0593C34.6159 50.9202 34.7246 50.7987 34.8536 50.7016C34.9826 50.6045 35.1295 50.5337 35.2859 50.4934L68.4734 41.935C68.7892 41.8536 69.1244 41.9009 69.4053 42.0666C69.6862 42.2324 69.8898 42.5029 69.9712 42.8187C70.0527 43.1345 70.0053 43.4697 69.8396 43.7506C69.6739 44.0315 69.4034 44.2351 69.0875 44.3165Z"
        fill="#F2F2F2"
      />
      <path
        d="M67.7842 59.5747L39.5086 66.8664C39.1929 66.9475 38.8579 66.9001 38.5772 66.7343C38.2964 66.5686 38.093 66.2981 38.0116 65.9825C37.9302 65.6668 37.9775 65.3317 38.143 65.0509C38.3086 64.7701 38.5789 64.5665 38.8945 64.4848L67.1701 57.1931C67.4859 57.1117 67.8211 57.159 68.102 57.3248C68.3829 57.4905 68.5865 57.761 68.6679 58.0768C68.7493 58.3926 68.702 58.7279 68.5363 59.0088C68.3705 59.2897 68.1 59.4932 67.7842 59.5747Z"
        fill="#F2F2F2"
      />
      <path
        d="M73.7331 62.3268L40.5457 70.8852C40.3892 70.9257 40.2264 70.9349 40.0664 70.9124C39.9063 70.8899 39.7523 70.8361 39.6131 70.7541C39.4739 70.6721 39.3523 70.5634 39.2551 70.4343C39.1579 70.3052 39.0871 70.1582 39.0468 70.0018C39.0064 69.8453 38.9973 69.6824 39.0199 69.5224C39.0426 69.3624 39.0965 69.2085 39.1787 69.0694C39.2608 68.9302 39.3696 68.8087 39.4988 68.7116C39.628 68.6145 39.775 68.5439 39.9315 68.5037L73.119 59.9453C73.4348 59.8638 73.77 59.9112 74.0509 60.0769C74.3318 60.2426 74.5354 60.5131 74.6168 60.829C74.6983 61.1448 74.6509 61.48 74.4852 61.7609C74.3195 62.0418 74.049 62.2454 73.7331 62.3268Z"
        fill="#F2F2F2"
      />
      <path
        d="M25.3917 38.3224L15.3853 40.9029C15.2339 40.9418 15.0732 40.919 14.9386 40.8396C14.8039 40.7601 14.7063 40.6305 14.6671 40.4792L12.3701 31.572C12.3312 31.4206 12.354 31.26 12.4335 31.1253C12.5129 30.9907 12.6425 30.893 12.7938 30.8538L22.8003 28.2734C22.9517 28.2345 23.1124 28.2573 23.247 28.3367C23.3817 28.4161 23.4793 28.5458 23.5185 28.6971L25.8155 37.6042C25.8543 37.7557 25.8316 37.9163 25.7521 38.051C25.6727 38.1856 25.5431 38.2832 25.3917 38.3224Z"
        fill="#E6E6E6"
      />
      <path
        d="M30.0363 56.3327L20.0298 58.9132C19.8784 58.952 19.7177 58.9293 19.5831 58.8498C19.4484 58.7704 19.3508 58.6408 19.3116 58.4894L17.0146 49.5823C16.9758 49.4309 16.9985 49.2702 17.078 49.1356C17.1574 49.0009 17.287 48.9033 17.4384 48.8641L27.4448 46.2836C27.5962 46.2447 27.7569 46.2675 27.8915 46.347C28.0262 46.4264 28.1238 46.556 28.163 46.7073L30.46 55.6145C30.4989 55.7659 30.4761 55.9266 30.3967 56.0612C30.3172 56.1959 30.1876 56.2935 30.0363 56.3327Z"
        fill="#E6E6E6"
      />
      <path
        d="M34.6808 74.343L24.6744 76.9234C24.5229 76.9623 24.3623 76.9395 24.2276 76.8601C24.093 76.7806 23.9953 76.651 23.9561 76.4997L21.6592 67.5926C21.6203 67.4411 21.6431 67.2805 21.7225 67.1458C21.802 67.0112 21.9316 66.9135 22.0829 66.8743L32.0894 64.2939C32.2408 64.255 32.4014 64.2778 32.5361 64.3572C32.6707 64.4367 32.7684 64.5663 32.8076 64.7176L35.1045 73.6247C35.1434 73.7762 35.1206 73.9368 35.0412 74.0715C34.9618 74.2061 34.8321 74.3037 34.6808 74.343Z"
        fill="#E6E6E6"
      />
      <path
        d="M100.452 16.8776H42.7087C40.7509 16.8798 38.8739 17.6585 37.4895 19.0429C36.1051 20.4273 35.3264 22.3043 35.3242 24.2621V92.0652C35.3264 94.0231 36.1051 95.9001 37.4895 97.2844C38.8739 98.6688 40.7509 99.4475 42.7087 99.4497H100.452C102.41 99.4475 104.287 98.6688 105.672 97.2844C107.056 95.9001 107.835 94.0231 107.837 92.0652V24.2621C107.835 22.3043 107.056 20.4273 105.672 19.0429C104.287 17.6585 102.41 16.8798 100.452 16.8776Z"
        fill="#E6E6E6"
      />
      <path
        d="M100.452 19.0034H42.7079C41.3137 19.005 39.977 19.5595 38.9912 20.5454C38.0053 21.5312 37.4508 22.8679 37.4492 24.2621V92.0653C37.4508 93.4595 38.0053 94.7961 38.9912 95.782C39.977 96.7678 41.3137 97.3224 42.7079 97.3239H100.452C101.846 97.3223 103.183 96.7678 104.168 95.782C105.154 94.7961 105.709 93.4595 105.71 92.0653V24.2621C105.709 22.8679 105.154 21.5312 104.168 20.5454C103.183 19.5595 101.846 19.005 100.452 19.0034Z"
        fill="white"
      />
      <path
        d="M106.519 107.699C113.964 107.699 119.999 101.664 119.999 94.2191C119.999 86.7743 113.964 80.739 106.519 80.739C99.0743 80.739 93.0391 86.7743 93.0391 94.2191C93.0391 101.664 99.0743 107.699 106.519 107.699Z"
        fill="#0F67D1"
      />
      <path
        d="M112.322 97.5346L109.006 94.2192L112.322 90.9038C112.651 90.574 112.837 90.1268 112.837 89.6605C112.837 89.1942 112.651 88.7469 112.322 88.4172C111.992 88.0874 111.545 87.9022 111.078 87.9022C110.612 87.9022 110.165 88.0874 109.835 88.4172L106.52 91.7326L103.204 88.4172C102.874 88.0874 102.427 87.9022 101.961 87.9022C101.494 87.9022 101.047 88.0874 100.718 88.4172C100.388 88.7469 100.203 89.1942 100.203 89.6605C100.203 90.1268 100.388 90.574 100.718 90.9038L104.033 94.2192L100.718 97.5346C100.388 97.8644 100.203 98.3116 100.203 98.7779C100.203 99.2443 100.388 99.6915 100.718 100.021C101.047 100.351 101.494 100.536 101.961 100.536C102.427 100.536 102.874 100.351 103.204 100.021L106.52 96.7058L109.835 100.021C110.165 100.351 110.612 100.536 111.078 100.536C111.545 100.536 111.992 100.351 112.322 100.021C112.651 99.6915 112.837 99.2443 112.837 98.7779C112.837 98.3116 112.651 97.8644 112.322 97.5346Z"
        fill="white"
      />
      <path
        d="M91.7638 56.2611H62.5631C62.4015 56.2613 62.2415 56.2296 62.0921 56.1679C61.9428 56.1061 61.807 56.0156 61.6927 55.9014C61.5784 55.7872 61.4877 55.6515 61.4258 55.5023C61.3639 55.353 61.332 55.193 61.332 55.0314C61.332 54.8697 61.3639 54.7097 61.4258 54.5604C61.4877 54.4112 61.5784 54.2755 61.6927 54.1613C61.807 54.0471 61.9428 53.9566 62.0921 53.8948C62.2415 53.8331 62.4015 53.8015 62.5631 53.8016H91.7638C92.0897 53.802 92.4021 53.9317 92.6324 54.1623C92.8627 54.3929 92.9921 54.7054 92.9921 55.0314C92.9921 55.3573 92.8627 55.6698 92.6324 55.9004C92.4021 56.131 92.0897 56.2607 91.7638 56.2611Z"
        fill="#E6E6E6"
      />
      <path
        d="M96.8364 60.4114H62.5631C62.4015 60.4116 62.2415 60.3799 62.0921 60.3182C61.9428 60.2565 61.807 60.1659 61.6927 60.0517C61.5784 59.9375 61.4877 59.8019 61.4258 59.6526C61.3639 59.5033 61.332 59.3433 61.332 59.1817C61.332 59.0201 61.3639 58.8601 61.4258 58.7108C61.4877 58.5615 61.5784 58.4259 61.6927 58.3117C61.807 58.1974 61.9428 58.1069 62.0921 58.0452C62.2415 57.9835 62.4015 57.9518 62.5631 57.952H96.8364C97.1625 57.952 97.4753 58.0815 97.7059 58.3121C97.9365 58.5428 98.0661 58.8555 98.0661 59.1817C98.0661 59.5078 97.9365 59.8206 97.7059 60.0512C97.4753 60.2818 97.1625 60.4114 96.8364 60.4114Z"
        fill="#E6E6E6"
      />
      <path
        d="M91.7638 74.8606H62.5631C62.4015 74.8607 62.2415 74.8291 62.0921 74.7673C61.9428 74.7056 61.807 74.6151 61.6927 74.5009C61.5784 74.3867 61.4877 74.251 61.4258 74.1018C61.3639 73.9525 61.332 73.7924 61.332 73.6308C61.332 73.4692 61.3639 73.3092 61.4258 73.1599C61.4877 73.0106 61.5784 72.875 61.6927 72.7608C61.807 72.6466 61.9428 72.5561 62.0921 72.4943C62.2415 72.4326 62.4015 72.4009 62.5631 72.4011H91.7638C92.0899 72.4011 92.4027 72.5307 92.6333 72.7613C92.8639 72.9919 92.9935 73.3047 92.9935 73.6308C92.9935 73.957 92.8639 74.2698 92.6333 74.5004C92.4027 74.731 92.0899 74.8606 91.7638 74.8606Z"
        fill="#E6E6E6"
      />
      <path
        d="M96.8364 79.0108H62.5631C62.4015 79.011 62.2415 78.9793 62.0921 78.9176C61.9428 78.8559 61.807 78.7653 61.6927 78.6511C61.5784 78.5369 61.4877 78.4013 61.4258 78.252C61.3639 78.1027 61.332 77.9427 61.332 77.7811C61.332 77.6195 61.3639 77.4595 61.4258 77.3102C61.4877 77.1609 61.5784 77.0253 61.6927 76.9111C61.807 76.7969 61.9428 76.7063 62.0921 76.6446C62.2415 76.5829 62.4015 76.5512 62.5631 76.5514H96.8364C96.998 76.5512 97.158 76.5829 97.3074 76.6446C97.4567 76.7063 97.5924 76.7969 97.7068 76.9111C97.8211 77.0253 97.9118 77.1609 97.9737 77.3102C98.0356 77.4595 98.0674 77.6195 98.0674 77.7811C98.0674 77.9427 98.0356 78.1027 97.9737 78.252C97.9118 78.4013 97.8211 78.5369 97.7068 78.6511C97.5924 78.7653 97.4567 78.8559 97.3074 78.9176C97.158 78.9793 96.998 79.011 96.8364 79.0108Z"
        fill="#E6E6E6"
      />
      <path
        d="M56.0211 62.2954H45.6873C45.531 62.2953 45.3811 62.2331 45.2706 62.1226C45.16 62.012 45.0978 61.8621 45.0977 61.7058V52.5073C45.0978 52.3509 45.16 52.201 45.2706 52.0905C45.3811 51.9799 45.531 51.9178 45.6873 51.9176H56.0211C56.1775 51.9178 56.3274 51.9799 56.4379 52.0905C56.5484 52.201 56.6106 52.3509 56.6108 52.5073V61.7058C56.6106 61.8621 56.5484 62.012 56.4379 62.1226C56.3274 62.2331 56.1775 62.2953 56.0211 62.2954Z"
        fill="#E6E6E6"
      />
      <path
        d="M56.0211 80.8949H45.6873C45.531 80.8947 45.3811 80.8325 45.2706 80.722C45.16 80.6114 45.0978 80.4616 45.0977 80.3052V71.1067C45.0978 70.9503 45.16 70.8005 45.2706 70.6899C45.3811 70.5794 45.531 70.5172 45.6873 70.517H56.0211C56.1775 70.5172 56.3274 70.5794 56.4379 70.6899C56.5484 70.8005 56.6106 70.9503 56.6108 71.1067V80.3052C56.6106 80.4616 56.5484 80.6114 56.4379 80.722C56.3274 80.8325 56.1775 80.8947 56.0211 80.8949Z"
        fill="#E6E6E6"
      />
      <path
        d="M91.7972 35.5562H70.2688C69.9426 35.5562 69.6299 35.4266 69.3992 35.196C69.1686 34.9654 69.0391 34.6526 69.0391 34.3265C69.0391 34.0003 69.1686 33.6875 69.3992 33.4569C69.6299 33.2263 69.9426 33.0967 70.2688 33.0967H91.7972C92.1234 33.0967 92.4361 33.2263 92.6668 33.4569C92.8974 33.6875 93.0269 34.0003 93.0269 34.3265C93.0269 34.6526 92.8974 34.9654 92.6668 35.196C92.4361 35.4266 92.1234 35.5562 91.7972 35.5562Z"
        fill="#CCCCCC"
      />
      <path
        d="M96.8698 39.7065H70.2688C70.1073 39.7065 69.9474 39.6747 69.7982 39.6129C69.649 39.5511 69.5134 39.4605 69.3992 39.3463C69.2851 39.2321 69.1945 39.0966 69.1327 38.9474C69.0709 38.7982 69.0391 38.6383 69.0391 38.4768C69.0391 38.3153 69.0709 38.1554 69.1327 38.0062C69.1945 37.857 69.2851 37.7214 69.3992 37.6072C69.5134 37.4931 69.649 37.4025 69.7982 37.3407C69.9474 37.2789 70.1073 37.2471 70.2688 37.2471H96.8698C97.196 37.2471 97.5087 37.3766 97.7394 37.6072C97.97 37.8379 98.0995 38.1506 98.0995 38.4768C98.0995 38.8029 97.97 39.1157 97.7394 39.3463C97.5087 39.5769 97.196 39.7065 96.8698 39.7065Z"
        fill="#CCCCCC"
      />
      <path
        d="M65.5034 44.7361H45.6561C45.4997 44.736 45.3498 44.6738 45.2393 44.5632C45.1288 44.4527 45.0666 44.3028 45.0664 44.1465V28.6567C45.0666 28.5004 45.1288 28.3505 45.2393 28.24C45.3498 28.1294 45.4997 28.0672 45.6561 28.0671H65.5034C65.6597 28.0672 65.8096 28.1294 65.9202 28.24C66.0307 28.3505 66.0929 28.5004 66.0931 28.6567V44.1465C66.0929 44.3028 66.0307 44.4527 65.9202 44.5632C65.8096 44.6738 65.6597 44.736 65.5034 44.7361Z"
        fill="#E6E6E6"
      />
    </g>
    <defs>
      <clipPath id="clip0_3432_5939">
        <rect width="120" height="107.699" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>

<div class="empty-header">
  Još uvek nema podataka.
</div>

<div class="empty-tooltip">
  Unesite filter za prikaz izveštaja.
</div>
</div>


