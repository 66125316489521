<kodit-card-container *ngIf="racunInfoDto">
  <div card-title>
    {{ naziv }} broj: <span>{{ racunInfoDto.broj }}</span>
  </div>

  <div card-subtitle class="p-mt-3">
    <div *ngIf="racunInfoDto.sefKomentar" style="font-size: 1rem;">
      {{racunInfoDto.sefKomentar}}
    </div>
    <div class="p-text-left">
      Datum izdavanja: <span>{{ racunInfoDto.datumIzdavanjaStr }}</span>
    </div>
    <div class="p-text-left p-mt-2" *ngIf="racunInfoDto.stornoBroj">
      Storno broj :
      <span>{{ racunInfoDto.stornoBroj }}</span>
    </div>
    <div class="p-text-left p-mt-2" *ngIf="racunInfoDto.razlogOtkazivanjaStorniranja">
      Razlog {{ razlogLabel }}:
      <span>{{ racunInfoDto.razlogOtkazivanjaStorniranja }}</span>
    </div>
    <div class="p-mt-2" >
      <kodit-povezani-racuni-info></kodit-povezani-racuni-info>
    </div>
  </div>

  <div class="p-d-flex p-flex-column" card-right-side>
    <div class="p-d-flex p-ai-center p-jc-start">

      <div *ngIf="racunInfoDto.statusRadnogNaloga.status === 0; else otherStatus"
           [ngClass]="'p-text-center info-badge status-racuna status-racuna-' + racunInfoDto.statusDto.statusBadgeStr">
        {{ racunInfoDto.statusDto.statusStr.toUpperCase() }}
      </div>

      <ng-template #otherStatus>
        <div [ngClass]="'p-text-center info-badge status-radnog-naloga status-radnog-naloga-' + racunInfoDto.statusRadnogNaloga.statusBadgeStr">
          {{ racunInfoDto.statusRadnogNaloga.statusStr.toUpperCase() }}
        </div>
      </ng-template>
        <div *ngIf="showAddPovezaniRacuni" >
          <button
            class="p-ml-2 p-button p-button-outlined"
            icon="fa-light fa-plus"
            [label]="buttonLabel"
            pButton
            type="button"
            (click)="openAddPovezaniRacunDialog()"
          ></button>
        </div>
    </div>

  </div>

  <div class="p-d-flex p-jc-between" card-body>
    <div>
      <div class="p-d-flex">
        <span class="body_title">
          {{ jeUlazniRacun ? 'Dobavljač' : 'Kupac' }}
        </span>
      </div>
      <div class="p-d-flex p-mt-3">
        <div class="p-pr-2 p-label">Naziv:</div>
        <button pButton type="button" [label]="racunInfoDto.strankaNaziv"
          class="p-text-left p-button-link p-p-0 text-underline" (click)="goToStranka()"></button>
      </div>
      <div class="p-d-flex p-mt-2">
        <div class="p-pr-2 p-label">Adresa:</div>
        <div class="p-text">{{ racunInfoDto.strankaAdresa }}</div>
      </div>
      <div class="p-d-flex p-mt-2">
        <div class="p-pr-2 p-label">PIB:</div>
        <div class="p-text">{{ racunInfoDto.strankaPIB }}</div>
      </div>
      <div class="p-d-flex p-mt-2">
        <div class="p-pr-2 p-label">MB:</div>
        <div class="p-text">{{ racunInfoDto.strankaMB }}</div>
      </div>
    </div>
  </div>
</kodit-card-container>

<div class="loading-card" *ngIf="!racunInfoDto">
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
</div>
