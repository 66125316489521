import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { GetIzvestajIzlaznihDokumenataQuery, IIzvestajIzlaznihDokumenataFilter, IzvestajiClient, PodtipIzvestajaVeleprodaja, TipIzvestajaVeleprodaja } from '@kodit/core/data-api';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { VeleprodajaIzvestajService } from '../../veleprodaja-izvestaj.service';
import { BaseStrankaFormService, FormHelper } from '@kodit/core/form-definitions';
import { AlertService, SharedService } from '@kodit/core/services';
@Component({
  selector: 'kodit-veleprodaja-filter-izvestaji',
  templateUrl: './veleprodaja-filter-izvestaji.component.html',
  styleUrls: ['./veleprodaja-filter-izvestaji.component.scss']
})
export class VeleprodajaFilterIzvestajiComponent implements OnInit {

  private _subs: Subscription = new Subscription();

  /**Props */
  filterData!: IIzvestajIzlaznihDokumenataFilter;
  form!: FormGroupTypeSafe<IIzvestajIzlaznihDokumenataFilter>;
  tableData : any;
  podvrstaIzvestaja = PodtipIzvestajaVeleprodaja;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _service: VeleprodajaIzvestajService,
    private _formHelper: FormHelper,
    private _client: IzvestajiClient,
    private _alertService: AlertService,
    private _sharedService: SharedService
   ){}

  ngOnInit(): void {

    this.form = this._service.getForm(this.filterData);

    this.form?.controls.tipIzvestajaVeleprodaja?.patchValue(TipIzvestajaVeleprodaja.PO_ARTIKLIMA);
    this.form?.controls.podtipIzvestajaVeleprodaja?.patchValue(PodtipIzvestajaVeleprodaja.KOMPLETAN);

    this._subs.add(
      this.form.controls.tipIzvestajaVeleprodaja?.valueChanges.subscribe((res)=>{
        if(res == TipIzvestajaVeleprodaja.PO_PARTNERIMA){
          this.form.controls.podtipIzvestajaVeleprodaja?.patchValue(PodtipIzvestajaVeleprodaja.KOMPLETAN);
          this._service._resetujFilter();
          this._service.setTableData = null;
        }
      })
    );

    this._subs.add(
      this.form.controls.podtipIzvestajaVeleprodaja?.valueChanges.subscribe(()=>{
          this._service._resetujFilter();
          this._service.setTableData = null;
      })
    );

  }

  public primeniFilter(): void{
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

     var filter = {
        filteri: {
          datumOd: this.form?.value.datumOd,
          datumDo: this.form?.value.datumDo,
          tipIzvestajaVeleprodaja: this.form?.value.tipIzvestajaVeleprodaja,
          podtipIzvestajaVeleprodaja: this.form?.value.podtipIzvestajaVeleprodaja,
          stranke: this.form?.value.stranke
        }
      }

      this._sharedService.displayLoadingScreen(true, 'Pribavljanje podataka...');
      this._subs.add(
        this._client
          .getIzvestajIzlaznihVeleprodaja(filter as GetIzvestajIzlaznihDokumenataQuery)
          .subscribe((result) => {
            if(result.succeeded){
              this._service.setTableData = result.data;
            }else {
              this._service.setTableData = null;
              this._alertService.addWarnMsg("Za primenjeni filter nema podataka!")
            }
          })
      );
  }

}
