<kodit-card-container>
    <div card-title>Predefinisani tekst</div>

    <div card-right-side>
      <button
        icon="far fa-edit"
        pButton
        type="button"
        label="Izmeni"
        class="p-button p-button-outlined"
        (click)="onEdit()"
      ></button>
    </div>

    <div class="p-d-flex p-jc-between" card-body>
      <div class="p-d-flex p-flex-column gap-6">
        <div class="p-d-flex p-ai-center" style="margin-bottom: 8px;">
          <div class="p-label">Tekst pre računa:</div>
          <div style="margin-left: 10px;" [innerHTML]="textBefore ? textBefore : '--'" ></div>
        </div>
        <hr>
        <div class="p-d-flex p-ai-center" style="margin-bottom: 8px;">
          <div class="p-label">Tekst posle računa:</div>
          <div style="margin-left: 10px;" [innerHTML]="textAfter ? textAfter : '--'"></div>
        </div>
      </div>
    </div>

</kodit-card-container>
