<div style="display: flex;  gap: 10px;">
  <kodit-field fieldLabel="Izaberite tip izveštaja: " fieldLabelFor="tip_izvestaja">
      <div class="flex-1" style="">
          <kodit-dropdown
            formCtrlId="vrsta-izvestaja-veleprodaja"
            [options]="izvestajOptions"
            [formCtrl]="form?.controls.tipIzvestajaVeleprodaja"
          ></kodit-dropdown>
        </div>
  </kodit-field>

  <kodit-field fieldLabel="Sumiran/kompletan: " fieldLabelFor="podtip_izvestaja">
    <div>
        <kodit-dropdown
          formCtrlId="vrsta-izvestaja-veleprodaja"
          [options]="podtipOptions"
          [ctrlDisabled]="disabled"
          [formCtrl]="form?.controls.podtipIzvestajaVeleprodaja"
        ></kodit-dropdown>
      </div>
</kodit-field>
</div>

<kodit-page>
  <kodit-veleprodaja-filter-izvestaji
  ></kodit-veleprodaja-filter-izvestaji>
</kodit-page>

<kodit-page>
  <kodit-veleprodaja-table-izvestaji
  ></kodit-veleprodaja-table-izvestaji>
</kodit-page>

