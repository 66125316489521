import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { AppLayoutComponent } from '../layouts/app-layout/app-layout.component';
import {
  AuthService,
  NotificationService,
  SefService,
  Tutorial,
} from '@kodit/core/services';
import { CoreService } from '../core-service';
import { filter } from 'rxjs/operators';
import {
  GetKorisnikTenantAccessResponse,
  GetNotifikacijaKorisnikaListQuery,
  IGetSefHealthStatusResponse,
  INotifikacijaKorisnikaDto, MojaFirmaClient,
  NotifikacijeClient,
  PackageType,
  ReadAllNotificationsComand, SefClient
} from '@kodit/core/data-api';
import { NavigationEnd, Router } from '@angular/router';
import { AfterLoginService } from '../../../../services/src/lib/security/after-login.service';
import { RacunTutorialsService } from '@kodit/core/core-ui';
import { ProgressItem, ProgressService } from '../progress.service';
import { TopbarService } from './topbar.service';
import { SEF_HEALTH_CHECK_IN_SECONDS } from '@kodit/core/shared';

const colors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d',
  '#5DDEA3',
  '#FF5959',
];

@Component({
  selector: 'kodit-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy, OnChanges {
  /** Subscription */
  private _subs: Subscription = new Subscription();

  /** Constants */
  maloprodaja_unos_url = '/maloprodaja/fiskalni/unos';

  /** Props */
  private intervalId: number | null = null;

  items: MenuItem[] = [];
  korisnikTenantAccesses$: Observable<GetKorisnikTenantAccessResponse>;
  name: string;
  email: string;
  notifikacijeKorisnika: INotifikacijaKorisnikaDto[] = [];
  isSefActiveDto: IGetSefHealthStatusResponse;
  tutorijali: Tutorial[] = [];
  progresses: ProgressItem[] = [];
  filters! : GetNotifikacijaKorisnikaListQuery;
  apiKey:string|null=null;

  /** Conditional props */
  shouldDisplayRacunYearFilter: boolean = true;
  shouldDisplayFiskalnaKasaStavkeRacunaTitle: boolean = false;

  constructor(
    private _authService: AuthService,
    public appLayout: AppLayoutComponent,
    public _coreService: CoreService,
    private _notificationClient: NotifikacijeClient,
    private _notificationService: NotificationService,
    private _router: Router,
    private _client: NotifikacijeClient,
    private _sefService: SefService,
    private _sefClient: SefClient,
    private _afterLoginService: AfterLoginService,
    private _racunTutorialsService: RacunTutorialsService,
    private _progressService: ProgressService,
    private _mojaFirmaClient: MojaFirmaClient,
    private service: TopbarService
  ) {
    this._detectPageRefresh();
  }

  ngOnInit() {

    this._mojaFirmaClient.getApiMenadzment().subscribe((res:any)=> {
      if(res && res.data && res.data.apiKeySEF){
        this.startSefHealthCheck();
      }
    });

    this.filters = new GetNotifikacijaKorisnikaListQuery({
      pageNumber: 0,
      pageSize: 10,
      samoNeprocitane: true
    });
    this._loadNotifications();
    this.prepareUserInfo();
    this._notificationsSub();
    this._subs.add(
      this._authService.getUserChanged.subscribe((result) => {
        if (result) {
          this.prepareUserInfo();
        }
      })
    );
    this._subs.add(
      this._sefService.getIsSefActive.subscribe(
        (sefHealth: IGetSefHealthStatusResponse) => {
          this.isSefActiveDto = sefHealth;
          if (!sefHealth.background) {
            this._afterLoginService.getDataAfterLogin();
          }
        }
      )
    );
    // this._subs.add(
    //   this._fiskalniService.getEsdcCardStatus.subscribe(
    //     (cardStatus: EsdcCardStatusChangeNotification) => {
    //       this.esdcCardStatusDto = cardStatus;
    //     }
    //   )
    // );
    this.loadTutorijals(this._router.url);
    this.loadProgresses();
    this.routeChange();

    //this.getTutorials();
    this._subs.add(
      this._router.events.subscribe((res) => {
        if (res instanceof NavigationEnd) {
          this.shouldDisplayFiskalnaKasaStavkeRacunaTitle =
            res.url === this.maloprodaja_unos_url;
        }
      })
    );
  }

  private startSefHealthCheck() {
    this.performSefHealthCheck(); // Initial call
    this.intervalId = window.setInterval(() => {
      this.performSefHealthCheck();
    }, SEF_HEALTH_CHECK_IN_SECONDS*1000); // 60000 ms = 1 minut
  }
  private stopSefHealthCheck() {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private performSefHealthCheck() {
    //console.log('Usao sa fronta u SEF health check:', new Date());
    this._sefClient.getSefHealhStatus().subscribe({
      next: (response) => {
        if (response.succeeded) {
          //console.log('Response', response.toJSON());
          const sefStatus: IGetSefHealthStatusResponse = {
            isActive: response.succeeded,
            background: colors[colors.length-1],
          };
          this._sefService.setIsSefActive = sefStatus;
        } else {
          console.error('SEF health status check failed:', response);
        }
      },
      error: (error) => {
        console.error('Error fetching SEF health status sa fronta:', error);
      },
    });
  }

  ngOnChanges(): void {
    this._loadNotifications();
    //this.handleOznaciKaoProcitano();
  }

  loadProgresses() {
    this._subs.add(
      this._progressService
        .getProgresses()
        .subscribe((progresses: ProgressItem[]) => {
          this.progresses = progresses;
        })
    );
  }

  logOff() {
    this._authService.logout();
  }

  // handleOznaciKaoProcitano() {
  //   this._subs.add(
  //     this._client
  //       .readAllNotificationsKorisnika(
  //         new ReadAllNotificationsComand({
  //           notifikacijeIds: this.notifikacijeKorisnika
  //             .map((x) => x.id)
  //             .slice(0, 5),
  //         })
  //       )
  //       .subscribe((res) => {
  //         if (res.succeeded) {
  //           location.reload();
  //         }
  //       })
  //   );
  // }

  openPodesavanjaSidebar(){
    this.service.showPodesavanjaSidebar();
  }

  openNotifikacijeSidebar(){
    this.service.showNotifikacijeSidebar();
  }

  openProfileSidebar(){
    this.service.showProfileSidebar()
  }

  gotToNotifikacije() {
    this._router.navigate(['/notifikacije']);
  }

  private loadTutorijals(route: string) {
    this.tutorijali = [];
    if (route.includes('racuni')) {
      //tutorijali = this._racunTutorialsService.getTutorials(route);

      // this._subs.add(this._racunTutorialsService.getTutorials2(route).subscribe(tutorials => {
      //           this.tutorijali = tutorials;

      // }));

      this._subs.add(
        this._racunTutorialsService
          .getTutorials(this._router.url)
          .subscribe((tutorials) => {
            this.tutorijali = tutorials;
          })
      );
    }
    // else if(route.includes('banka')){
    //    tutorijali = this._bankaTutorialsService.getTutorials(route);
    //    if (tutorijali !== null) {
    //      this.tutorijali = tutorijali;
    //    }
    // }

    // else if(route.includes('robno')){
    //   tutorijali = this._robnoTutorialsService.getTutorials(route);
    //   if (tutorijali !== null) {
    //     this.tutorijali = tutorijali;
    //   }
    // }

    // else if(route.includes('stranke')){
    //   tutorijali = this._strankeTutorialsService.getTutorials(route);
    //   if (tutorijali !== null) {
    //     this.tutorijali = tutorijali;
    //   }
    // }
  }

  private routeChange() {
    this._subs.add(
      this._router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.loadTutorijals(event.url);
        }
      })
    );
  }

  // private getTutorials() {
  //  this.tutorijali.push(this._joyrideService.getTutorials());
  // }

  private prepareUserInfo() {
    const userData = this._authService.getUserData;
    const element = document.querySelector('.profile-image');
    const height = document.getElementById('avatar-initials').offsetHeight;
    this.name = userData.fullName;
    this.email = userData.email;
    const initials =
      userData.fullName.split(' ')[0].charAt(0).toUpperCase() +
      userData.fullName.split(' ')[1].charAt(0).toUpperCase();

    const charIndex = initials.charCodeAt(0) - 65;
    const colorIndex = charIndex % 19;
    const stylle = `background-color: ${colors[colorIndex]};
    border-radius: 10px;
    width: ${height}px;
    height: ${height}px;
    color: #FFF;
    text-align: center;
    line-height: ${height}px;`;

    element.setAttribute('style', stylle);

    element.innerHTML = initials;

    this.korisnikTenantAccesses$ = this._coreService.getKorisnikTenantAccesses(
      userData.email
    );
  }

  private _loadNotifications() {
    this._subs.add(
      this._notificationClient
        .getNotifikacijaKorisnikaList(this.filters)
        .subscribe((res) => {
          this._notificationService.addTopbarNotifications(
            res.data
          );
        })
    );
  }

  private _detectPageRefresh() {
    this._subs.add(
      this._router.events
        .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
        .subscribe((event) => {

          this.shouldDisplayFiskalnaKasaStavkeRacunaTitle =
            event.url === this.maloprodaja_unos_url;
        })
    );
  }

  private _notificationsSub() {
    this._subs.add(
      this._notificationService.getTopbarNotifications.subscribe((res) => {
        this.notifikacijeKorisnika = res;
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
    this.stopSefHealthCheck();
  }
}
