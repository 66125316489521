import { Component, OnDestroy, OnInit } from '@angular/core';
import { PPPdvForm, PPPdvService } from '../pppdv.service';
import { ActivatedRoute } from '@angular/router';
import { PPPDVFormService } from '../pppdv-form.service';
import { merge, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { AbstractControlTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import {
  CreatePdvEvidencijaZbirna39Command,
  CreatePPPDVEvidencijaCommand,
  GetSystemCalculationQuery,
  IPPPDVEvidencijaDto,
  PdvEvidencijeClient,
  PdvOpseg,
  ResultOfString,
  UpdatePdvEvidencijaZbirna39Command,
  UpdatePPPDVEvidencijaCommand
} from '@kodit/core/data-api';
import { ConfirmationService } from 'primeng/api';
import { AlertService, LocationService, SharedService } from '@kodit/core/services';
import { FormHelper } from '@kodit/core/form-definitions';
import { BreadcrumbService } from 'xng-breadcrumb';
import { PdvEvidencijaService } from '../../pdv-evidencija.service';

@Component({
  selector: 'kodit-pppdv-form',
  templateUrl: './pppdv-form.component.html',
  styleUrls: ['./pppdv-form.component.scss'],
})
export class PppdvFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form!: PPPdvForm;
  shouldDisableForm: boolean = false;
  isKoriguj: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _fs: PPPDVFormService,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _formHelper: FormHelper,
    private _sharedService: SharedService,
    private _client: PdvEvidencijeClient,
    private _alert: AlertService,
    private _service: PdvEvidencijaService
  ) {
    this.isKoriguj = false;
  }

  ngOnInit(): void {
    this.shouldDisableForm = this._route.snapshot.data['isPregledKorigovane'];
    this.isKoriguj = this._route.snapshot.data['isKoriguj'];
    this.form = this._fs.getForm(
      this._route.snapshot.data['evidencija'],
      this.isKoriguj
    );
  }

  recalculate(ukupnoControl: any, controls: any[]): void {
    if (controls.every((item) => item.value === null)) {
      ukupnoControl.patchValue(null);
      return;
    }
    let sum = 0;
    controls
      .filter((item) => item)
      .forEach((control: any) => {
        sum += control.value;
      });
    ukupnoControl.patchValue(sum);
  }

  handleRouteBack() {
    this._locationService.routeBack();
  }

  handleCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav nesnimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeToCancelPdvEvidencija();
      },
    });
  }

  automatizacija() {
    if(this.form?.controls.godina?.value == null){
      this._alert.addWarnMsg("Morate uneti godinu da bi automatizovali formu.");
      return;
    }

    var vatPeriodRange : string;
    var pdvOpseg = this._service.getOpsegValue;
    if(pdvOpseg == PdvOpseg.KVARTALNO){
      vatPeriodRange = "Quarterly";
    }else {
      vatPeriodRange = "Monthly";
    }

    if(!this._fs.proveraPerioda(pdvOpseg ?? PdvOpseg.MESECNO)){
      this._alert.addWarnMsg("Period mora biti mesec ili kvartal koji je već prošao.");
      return;
    }

    var vatPeriod = this._fs.setVatPeriodZaAutomatizaciju();

    var filterZaAutomatizaciju = {
      filterZaAutomatizacijuPPPDV: {
        year: this.form?.controls.godina?.value,
        vatPeriodRange: vatPeriodRange,
        vatPeriod: vatPeriod
      }
    };

    this._sharedService.displayLoadingScreen(
      true,
      'Pribavljanje podataka...'
    );
    this._subs.add(
      this._client
        .getSystemCalculationQuery(filterZaAutomatizaciju as GetSystemCalculationQuery)
        .subscribe((res) => {
          if (res.succeeded) {
            if(res.data != null){
              this._fs.automatizacijaForme(res.data);
              this._alert.addSuccessMsg("Uspešno pribavljeni podaci.")
            }else {
              this._alert.addWarnMsg("Nema podataka za ovaj period.");
              return;
            }
          }
          this._alert.addFailedMsg(res.messages![0]);
        })
    );
  }

  handleSave() {
    // check is it valid
    if (!this.form!.valid) {
      this._formHelper.invalidateForm(this.form!);
      return;
    }

    if(this.isKoriguj){
      this._sharedService.displayLoadingScreen(
        true,
        'Korigovanje evidencije prethodnog poreza...'
      );
      this._subs.add(
        this._client
          .sefKorigujPPPDVEvidenciju(
            this.form.value.id!,
            this.form.value as UpdatePPPDVEvidencijaCommand
          )
          .subscribe((res) => {
            if (res.succeeded) {
              this._alert.addSuccessMsg(res.data!);
              this._locationService.routeBack();
            }
            this._alert.addFailedMsg(res.messages![0]);
          })
      );
    }
    else if(this._fs.jeEdit){
      this._sharedService.displayLoadingScreen(
        true,
        'Ažuriranje evidencije prethodnog poreza...'
      );
      this._subs.add(
        this._client
          .updatePPPDV(
            this.form.value.id!,
            this.form.value as UpdatePPPDVEvidencijaCommand
          )
          .subscribe((res) => {
            if (res.succeeded) {
              this._alert.addSuccessMsg(res.data!);
              this._locationService.routeBack();
            }
            this._alert.addFailedMsg(res.messages![0]);
          })
      );
    }
    else{
      this._sharedService.displayLoadingScreen(
        true,
        'Kreiranje evidencije prethodnog poreza...'
      );
      this._subs.add(
        this._client
          .createPPPDV(this.form!.value as CreatePPPDVEvidencijaCommand)
          .subscribe((res: ResultOfString) => {
            if (res.succeeded) {
              this._alert.addSuccessMsg(res.data!);
              this._locationService.routeBack();
            }
            this._alert.addFailedMsg(res.messages![0]);
          })
      );
    }
  }

  ngOnDestroy() {
    this._fs.resetForm();
    this._subs.unsubscribe();
  }
}
