<div class="izvor-wrapper p-fluid">
  <kodit-field fieldLabel="Izvor fajla">
    <kodit-izvod-fajl-izvor-dropdown
      [formCtrl]="miniForm.controls.banka"
      (onItemChanged)="handleIzvorFajlaChanged($event)"
    ></kodit-izvod-fajl-izvor-dropdown>
  </kodit-field>
</div>

<!--<div *ngIf="jeAik" class="p-fluid">-->
<!--  <div class="p-field">-->
<!--    <label kodit-form-label>Vrsta izvoda</label>-->
<!--    <p-selectButton-->
<!--      [options]="options"-->
<!--      [(ngModel)]="value"-->
<!--    >-->
<!--    </p-selectButton>-->
<!--  </div>-->
<!--</div>-->


<div *ngIf="!showCsvUpload" class="p-fluid">
  <kodit-field fieldLabel="Odabir banke" *ngIf="jeAsseco">
    <kodit-banka-dropdown
      (onItemChange)="onBankaSelected($event)"
    ></kodit-banka-dropdown>
  </kodit-field>

  <kodit-file-upload
    (onUpload)="onUpload($event)"
    [uploadCompleted]="uploadCompleted"
    [allowMultipleFiles]="true"
    fileTypes="text/xml"
    descriptionText="Izaberite XML fajl klikom na plus ili ga prevucite ovde"
  ></kodit-file-upload>
</div>

<ng-container *ngIf="showCsvUpload">
  <div class="p-fluid">
    <kodit-field fieldLabel="Broj izvoda" fieldLabelFor="broj-izvoda">
      <kodit-text-input
        formCtrlId="broj-izvoda"
        [formCtrl]="miniForm.controls.brojIzvoda"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Tekući račun">
      <kodit-tekuci-racun-dropdown
        [tekuciRacunCtrl]="miniForm.controls.tekuciRacunId"
      >
      </kodit-tekuci-racun-dropdown>
    </kodit-field>

    <kodit-file-upload
      (onUpload)="onUpload($event)"
      [uploadCompleted]="uploadCompleted"
      [allowMultipleFiles]="true"
      fileTypes="text/csv"
      descriptionText="Izaberite CSV fajl klikom na plus ili ga prevucite ovde"
    ></kodit-file-upload>
  </div>
</ng-container>
