import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { VeleprodajaIzvestajService } from '../../veleprodaja-izvestaj.service';

import { Data } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { GetIzvestajIzlaznihDokumenataPdfQuery, IIzvestajIzlaznihDokumenataFilter, IzvestajiClient, IzvestajIzlaznihDokumenataDto, TipIzvestajaVeleprodaja, PodtipIzvestajaVeleprodaja, Stranka, StrankaMultiSelectItem } from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';

@Component({
  selector: 'kodit-veleprodaja-table-izvestaji',
  templateUrl: './veleprodaja-table-izvestaji.component.html',
  styleUrls: ['./veleprodaja-table-izvestaji.component.scss']
})
export class VeleprodajaTableIzvestajiComponent implements OnInit {

  private _subs: Subscription = new Subscription();
  columns : Array<any> = [];
  form!: FormGroupTypeSafe<IIzvestajIzlaznihDokumenataFilter>;
  tableData : any;

  datumOd: Data | undefined;
  datumDo: Data | undefined;
  tipIzvestaja : TipIzvestajaVeleprodaja | undefined;
  podtipIzvestaja : PodtipIzvestajaVeleprodaja | undefined;
  nazivFajla : string = "izvestaj-po-artiklima-kompletan";
  tip = TipIzvestajaVeleprodaja;
  podtip = PodtipIzvestajaVeleprodaja;
  stranke :  StrankaMultiSelectItem[] | undefined;
  filterData!: IIzvestajIzlaznihDokumenataFilter;

  constructor(
    private _service: VeleprodajaIzvestajService,
    private _client: IzvestajiClient,
  ) { }

  ngOnInit(): void {

    this._subs.add(
      this._service.getTableDataObservable.subscribe(data => {
        this.tableData = data;
        this._definisiKolone();
      })
    );

    this.form = this._service.getForm(this.filterData);

    this._subs.add(
      this.form.valueChanges.subscribe((res) =>{
        this._definisiImeFajla(res.tipIzvestajaVeleprodaja, res.podtipIzvestajaVeleprodaja);
      }
      )
    );
  }

  private _definisiKolone(): void{
    if(this.form?.controls.podtipIzvestajaVeleprodaja?.value == PodtipIzvestajaVeleprodaja.KOMPLETAN)
    {
      this.columns = [
        { field: 'broj', header: 'Broj' },
        { field: 'nazivPodgrupacije', header: this.form.controls.tipIzvestajaVeleprodaja?.value == TipIzvestajaVeleprodaja.PO_ARTIKLIMA
          ? 'Kupac' : 'Naziv robe'},
        { field: 'datum', header: 'Datum' },
        { field: 'jedinicaMere', header: 'Jed. mere' },
        { field: 'kolicina', header: 'Količina' },
        { field: 'cena', header: 'Cena bez PDV-a' },
        { field: 'vrednostRobe', header: 'Vrednost robe' },
        { field: 'rabat', header: 'Rabat' },
        { field: 'pdv', header: 'PDV' },
        { field: 'fakturisanaVrednost', header: 'Fakturisana vrednost' }
      ];
    }else{
      this.columns = [
        { field: 'nazivPodgrupacije', header: 'Naziv robe'},
        { field: 'jedinicaMere', header: 'Jed. mere' },
        { field: 'kolicina', header: 'Količina' },
        { field: 'vrednostRobe', header: 'Vrednost robe' },
        { field: 'rabat', header: 'Rabat' },
        { field: 'pdv', header: 'PDV' },
        { field: 'fakturisanaVrednost', header: 'Fakturisana vrednost' }
      ];
    }
  }

  exportToExcel = () => {

    const workbook = XLSX.utils.book_new();

    this.tableData.forEach((table: { nazivGrupacije: string; izlazniDokumentiIzvestajDto
      : { [x: string]: any; }[]; }, index: number) => {
      const tableName = (table.nazivGrupacije || `Tabela ${index + 1}`).slice(0, 30);
      const worksheet = XLSX.utils.aoa_to_sheet([
        this.columns.map(col => col.header),
        ...table.izlazniDokumentiIzvestajDto
        .map((row: { [x: string]: any; }) =>
          this.columns.map(col => row[col.field] || '')
        )
      ]);

      XLSX.utils.book_append_sheet(workbook, worksheet, tableName);
    });

    XLSX.writeFile(workbook, `${this.nazivFajla}.xlsx`);
  };

  private _definisiImeFajla(tip: TipIzvestajaVeleprodaja, podtip: PodtipIzvestajaVeleprodaja)
  {
    if(tip == TipIzvestajaVeleprodaja.PO_ARTIKLIMA){
      if(podtip == PodtipIzvestajaVeleprodaja.KOMPLETAN){
        this.nazivFajla = "izvestaj-po-artiklima-kompletan";
      }else {
        this.nazivFajla = "izvestaj-po-artiklima-sumiran";
      }
    }else {
      this.nazivFajla = "izvestaj-po-partnerima";
    }
  }

  public exportToPDF() : void{

    var data = this.mapToPDFData();

    this._subs.add(
      this._client
        .getIzvestajIzlaznihDokumenataPdf(data as GetIzvestajIzlaznihDokumenataPdfQuery)
        .subscribe((res) => {
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.download =  `${this.nazivFajla}.pdf` ;
          link.href = window.URL.createObjectURL(blob);
          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
        })
    );
  }

  public mapToPDFData(): any {

    const mappedData =  {
        izvestajIzlaznihDokumenataDto: this.tableData.map((item) => ({
          nazivGrupacije: item.nazivGrupacije,
          izlazniDokumentiIzvestajDto: item.izlazniDokumentiIzvestajDto?.map((doc) => ({
              broj: doc.broj,
              nazivPodgrupacije: doc.nazivPodgrupacije,
              datum: this.form?.controls.podtipIzvestajaVeleprodaja?.value == PodtipIzvestajaVeleprodaja.KOMPLETAN ? doc.datum : undefined,
              jedinicaMere: doc.jedinicaMere,
              kolicina: doc.kolicina,
              cena: doc.cena,
              vrednostRobe: doc.vrednostRobe,
              rabat: doc.rabat,
              pdv: doc.pdv,
              fakturisanaVrednost: doc.fakturisanaVrednost,
            })),
          ukupnoKolicina: item.ukupnoKolicina,
          ukupnoVrednostRobe: item.ukupnoVrednostRobe,
          ukupnoRabat: item.ukupnoRabat,
          ukupnoPdv: item.ukupnoPdv,
          ukupnoFakturisanaVrednost: item.ukupnoFakturisanaVrednost
        })),
        filteri : {
          datumOd : this.form?.controls.datumOd?.value,
          datumDo : this.form?.controls.datumDo?.value,
          tipIzvestajaVeleprodaja : this.form?.controls.tipIzvestajaVeleprodaja?.value,
          podtipIzvestajaVeleprodaja : this.form.controls.podtipIzvestajaVeleprodaja?.value,
          stranke : this.form.controls.stranke?.value
        }
    };

    return mappedData;
}


}
