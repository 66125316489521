import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGetSefHealthStatusResponse,
  ResultOfArhivskaExistsResponse,
} from '@kodit/core/data-api';

@Injectable({
  providedIn: 'root',
})
export class SefService {
  isSefActive$ = new BehaviorSubject<IGetSefHealthStatusResponse>({
    isActive: null,
    background: null,
  });

  get getIsSefActive() {
    return this.isSefActive$.asObservable();
  }

  set setIsSefActive(isActive: IGetSefHealthStatusResponse) {

    this.isSefActive$.next(isActive);
  }

  // shouldDisplayApiKeyDialog$ = new BehaviorSubject<boolean>(null);

  // get getShouldDisplayApiKeyDialog() {
  //   return this.shouldDisplayApiKeyDialog$.asObservable();
  // }

  // set setShouldDisplayApiKeyDialog(show: boolean) {
  //   this.shouldDisplayApiKeyDialog$.next(show);
  // }
}
