import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '@kodit/core/services';
import {
  FilterChipDto,
  GetUslugaTableQuery,
  IPredmetStavkeFilterDto,
  PredmetStavkeFilterDto,
  ResultOfUslugaCommandResponse,
  UslugaTableDto,
  UslugeClient,
} from '@kodit/core/data-api';
import { DynamicDialogRef, DynamicDialogService } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import {
  DialogSize,
  DynamicDialogConfig,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { UslugaFormComponent } from '../usluga-form/usluga-form.component';
import {
  ActionType,
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { UslugaFilterService } from '../usluga-filter/usluga-filter.service';
import { UslugaFilterComponent } from '../usluga-filter/usluga-filter.component';
import { toCamelCase } from '@kodit/core/shared';

@Component({
  selector: 'kodit-usluga-table',
  templateUrl: './usluga-table.component.html',
  styleUrls: ['./usluga-table.component.scss'],
})
export class UslugaTableComponent
  implements OnInit, OnDestroy, OnTableInit, OnDynamicDialogInit {
  /** Configs */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  private _subs: Subscription = new Subscription();
  uslugeTable: UslugaTableDto[] = [];
  chipItems: FilterChipDto[] = [];
  skipFirst: number = 0;
  numberOfRowsDisplayed: number = 0;

  constructor(
    private _alertService: AlertService,
    private _client: UslugeClient,
    private _dialogService: DynamicDialogService,
    private _uslugaFilterService: UslugaFilterService
  ) {}

  ngOnInit(): void {
    this._load(new PredmetStavkeFilterDto());
    // set table config
    this.setTableConfig().then();
    //sub to filters
    this._subs.add(
      this._uslugaFilterService.getAdvanceFilterSubmitted.subscribe(
        (filterResult) => {
          if (filterResult?.shouldApplyFilter) {
            this.tableConfig.advancedFilter.data = filterResult.filterData;
            this._load(filterResult.filterData);
          }
        }
      )
    );
  }

  //#region Table configs

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Usluge',
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabrane usluge?',
      deleteMessage: 'Da li ste sigurni da želite da izbrišete uslugu {param}?',
      deleteMessageParams: 'naziv',
      columns: [
        {
          field: 'naziv',
          header: 'Naziv',
          type: 'text',
          columns: [
            { field: 'sifra', header: 'šifra', type: 'text' },
            {
              field: 'jedinicaMereDescription',
              header: 'jedinica mere',
              type: 'badge',
            },
          ],
        },
        {
          field: 'cenaBezPdv',
          header: 'Cena bez pdv-a',
          type: 'currency',
          currencyAlphaCharField: 'valutaText',
        },
        {
          field: 'cenaSaPdv',
          header: 'Cena sa pdv-om',
          type: 'currency',
          currencyAlphaCharField: 'valutaText',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unesite uslugu',
          noAccessTooltip: 'Nemate ovlašćenja za unos usluge',
          callback: () => {
            this.dialogConfig.data = { id: null };
            this.dialogConfig.header = 'Unos podataka usluge';
            this.openDialog(this.dialogConfig);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          hasAccessTooltip: 'Izmenite uslugu',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu usluge',
          callback: (index: number) => {
            this.dialogConfig.data = { id: this.uslugeTable[index]?.id };
            this.dialogConfig.header = 'Izmena podataka usluge';
            this.openDialog(this.dialogConfig);
          },
        },
        // {
        //   type: ActionType.DELETE,
        //   tipPristupa: TipPristupa.USLUGA_DELETE,
        //   hasAccessTooltip: 'Obrišite uslugu',
        //   noAccessTooltip: 'Nemate ovlašćenja za brisanje usluge',
        //   callback: (index: number) => {
        //      const forDelete = this.uslugeTable[itemIndex];
        // this._deleteSub = this._client.delete(forDelete.id).subscribe(
        //   () => {
        //     this.uslugeTable.splice(itemIndex, 1);
        //     this._alertService.addSuccessMsg(
        //       `Usluga ${forDelete.naziv} uspešno obrisana.`
        //     );
        //   },
        //   (error) => {
        //     this._alertService.addFailedMsg(error);
        //   }
        // );
        //   },
        // },
      ],
      advancedFilter: {
        component: UslugaFilterComponent,
        data: new PredmetStavkeFilterDto(),
      },
    });
  }

  //#endregion Table configs

  openDialog(config: DynamicDialogConfig) {
    const ref: DynamicDialogRef = this._dialogService.open(
      UslugaFormComponent,
      config
    );

    this._subs.add(
      ref.onClose.subscribe((result: ResultOfUslugaCommandResponse) => {
        if (result) {
          this._updateFilterDataAndReload();
        }
      })
    );
  }
  private _updateFilterDataAndReload() {
    this.tableConfig.advancedFilter.data.pageNumber = this.skipFirst;
    this.tableConfig.advancedFilter.data.pageSize = this.numberOfRowsDisplayed;
    this._load(this.tableConfig.advancedFilter.data);
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    this.tableConfig.advancedFilter.data[toCamelCase(removedChip.key)] =
      removedChip.defaultValue;
    this._updateFilterDataAndReload();
  }

  // onOpenFilter() {
  //   this.filterConfig.data = this.filterDto;

  //   const ref = this._filterService.open(
  //     UslugaFilterComponent,
  //     this.filterConfig
  //   );

  //   ref.onClose.subscribe((request: IGetUslugaTableRequest) => {
  //     if (request) {
  //       this.filterDto = request;
  //       this.onTableRefresh(request);
  //     } else {
  //       this.filterDto = null;
  //     }
  //   });
  // }

  // onSubmitFilter(data: IGetUslugaTableRequest) {
  //   this.onTableRefresh(new GetUslugeTableQuery(data));
  // }

  private _load(request: IPredmetStavkeFilterDto) {
    this._subs.add(
      this._client.getForTable(request as GetUslugaTableQuery).subscribe(
        (res) => {
          this.uslugeTable = res.data.responseList;
          this.chipItems = res.data.activeFilters;
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
