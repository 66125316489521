<div [formGroup]="form" class="p-d-flex p-flex-column p-fluid filters">
  <div class="p-d-flex p-flex-wrap " style="justify-content: space-between;">
    <div style="display: flex; " class="gap-8">
      <!-- Datum  od -->
      <div
        class="flex-1"
      >
        <kodit-field fieldLabel="Datum od: " fieldLabelFor="datumOd">
          <kodit-calendar
            [formCtrl]="form.controls.datumOd!"
            formCtrlId="datumOd"
          ></kodit-calendar>
        </kodit-field>
      </div>
      <!-- Datum do -->
      <div
        class="flex-1"
      >
        <kodit-field fieldLabel="Datum do: " fieldLabelFor="datumDo">
          <kodit-calendar
            [formCtrl]="form.controls.datumDo!"
            formCtrlId="datumDo"
          ></kodit-calendar>
        </kodit-field>
      </div>
      <div
        *ngIf="form.controls.podtipIzvestajaVeleprodaja?.value  == podvrstaIzvestaja.SUMIRAN"
      >
        <kodit-field fieldLabel="Stranke: " fieldLabelFor="stranke">
          <kodit-stranka-filter-multiselect
            ctrlId="stranke"
            [strankeFormArray]="form.controls.stranke"
          ></kodit-stranka-filter-multiselect>
        </kodit-field>
      </div>
    </div>
    <div style="display: flex; align-items: center">
      <kodit-button
        class="p-d-flex p-align-end"
        btnLabel="Primeni"
        btnIcon="fa-regular fa-magnifying-glass"
        (btnClicked)="primeniFilter()"
      ></kodit-button>
    </div>
  </div>
</div>

