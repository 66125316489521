import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  ActionMode,
  ActionType,
  DynamicDialogConfig,
  DynamicDialogService,
  TableConfig,
} from '@kodit/core/shared-ui';
import { DialogSize } from '../../../../../../shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  FilterChipDto,
  GetSenderSignaturesTableQuery,
  GetUplateQuery,
  IPdvEvidencijaZbirna39TableDto,
  MojaFirmaClient,
  PaginatedResultOfSenderSignatureTableDto,
  PonistiEvidentiranuUplatuCommand, RacunTableDto,
  SenderSignatureTableDto, SyncStatusPojedinacnogIzlaznihRacunaCommand,
  TipPristupa,
  TipRacuna,
  TipStranke,
  UplataTableDto
} from '@kodit/core/data-api';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AlertService,
  ConfigService,
  LocationService,
  SharedService,
} from '@kodit/core/services';
import { SenderSignatureFormComponent } from './sender-signature-form/sender-signature-form.component';
import { selectFilterByKey } from '../../../../../../shared-subforms/src/lib/racun/state/filters/filter.selectors';
import { finalize } from 'rxjs/operators';
import { UplateIsplateFilterComponent } from '../../../../../../shared-subforms/src/lib/izvod/uplate-isplate-filter/uplate-isplate-filter.component';
import { setFilter } from '../../../../../../shared-subforms/src/lib/racun/state/filters/filter.actions';
import { ConfirmationService } from 'primeng/api';
import { DnsSettingsFormComponent } from './dns-settings-form/dns-settings-form.component';

@Component({
  selector: 'kodit-sender-signature-table',
  templateUrl: './sender-signature-table.component.html',
  styleUrls: ['./sender-signature-table.component.scss'],
})
export class SenderSignatureTableComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  paginatedData!: PaginatedResultOfSenderSignatureTableDto;
  table: SenderSignatureTableDto[] = [];
  private _skipFirst = 0;
  numberOfRowsDisplayed = 0;
  private _isBusy = false;
  filters: GetSenderSignaturesTableQuery;
  isFirstLoad = true;

  /** Configurations */
  tableConfig!: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  constructor(
    private _client: MojaFirmaClient,
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService,
    private _storage: Store,
    private _configService: ConfigService,
    private _locationService: LocationService,
    private _confirmationService: ConfirmationService,
    private _sharedService: SharedService,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._storage.select(selectFilterByKey('MAILS')).subscribe((data) => {
        if (data) {
          this.filters = JSON.parse(data);
          this._skipFirst = this.filters.pageNumber ?? 0;
          this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
        } else {
          this.filters = new GetSenderSignaturesTableQuery({
            pageNumber: this._skipFirst,
            pageSize: this.numberOfRowsDisplayed,
          });
        }
        this._load();
      })
    );

    this.setTableConfig().then();
  }

  private _load() {
    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters) {
      this.filters = new GetSenderSignaturesTableQuery({
        pageNumber: this._skipFirst,
        pageSize: this.numberOfRowsDisplayed,
      });
    }

    this._subs.add(
      this._client
        .getSenderSignatures(this.filters)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe((res) => {
          this.paginatedData = res;
          this.table = res.data;
        })
    );
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst;
  }

  private _getPaginatedItem(index: number): SenderSignatureTableDto {
    return this.table[this._getPaginatedIndex(index)];
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Email adrese',
      isLazy: true,
      lazyCallbackFunction: (event) => {
        if (!this.isFirstLoad) {
          this._skipFirst = event.first;
          this.numberOfRowsDisplayed = event.rows;
        }
        this.updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this._syncEmailove();
          this.isFirstLoad = false;
        }
      },
      columns: [
        {
          field: 'email',
          emptyCellField: 'emptyText',
          header: 'Email',
          type: 'text',
        },
        {
          field: 'name',
          emptyCellField: 'emptyText',
          header: 'Ime',
          type: 'text',
        },
        {
          field: 'replyToEmail',
          emptyCellField: 'emptyText',
          header: 'Email za odgovore',
          type: 'text',
        },
        {
          field: 'confirmed',
          header: 'Potvrđeno',
          type: 'bool',
        },
        {
          field: 'default',
          header: 'Podrazumevano',
          type: 'bool',
        },
        {
          field: 'defaultForExternal',
          header: 'Podrazumevano za eksterne servise',
          type: 'bool',
        },
      ],
      headerActions: [
        {
          type: ActionType.CUSTOM,
          hasAccessTooltip: 'DNS podešavanja',
          noAccessTooltip: 'Nemate ovlašćenja za podešavanje DNS-a',
          label: 'DNS podešavanja',
          icon: 'fa-light fa-sliders',
          callback: () => this._openDnsSettingsForm(),
        },
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unesite email adresu',
          noAccessTooltip: 'Nemate ovlašćenja za unos računa',

          callback: () => this._openCreateForm(),
        },
      ],
      rowActions: [
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          icon: 'fa-solid fa-octagon-check',
          hasAccessTooltip: 'Označite kao podrazumevano',
          noAccessTooltip: 'Nemate ovlašćenje za ovu akciju',
          callback: (index: number) => {
            this._setAsDefault(index);
          },
          shouldDisplayByCondition: (rowData: SenderSignatureTableDto) => {
            return !rowData.default && rowData.confirmed;
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          icon: 'fa-solid fa-user-check',
          hasAccessTooltip: 'Označite kao podrazumevano za eksterne servise',
          noAccessTooltip: 'Nemate ovlašćenje za ovu akciju',
          callback: (index: number) => {
            this._setAsDefaultForExternal(index);
          },
          shouldDisplayByCondition: (rowData: SenderSignatureTableDto) => {
            return !rowData.defaultForExternal && rowData.confirmed;
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          icon: 'fa-light fa-rotate',
          hasAccessTooltip: 'Izvršite sinhronizaciju',
          noAccessTooltip: 'Nemate ovlašćenje za sinhronizaciju',
          callback: (index: number) => {
            this._syncPojedinacanEmail(index);
          },
          shouldDisplayByCondition: (rowData: SenderSignatureTableDto) => {
            return !rowData.confirmed;
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          icon: 'fa-light fa-circle-envelope',
          hasAccessTooltip: 'Ponovo pošaljite mail potvrde',
          noAccessTooltip: 'Nemate ovlašćenje za ponovno slanje potvrde',
          callback: (index: number) => {
            this._resendConfirmationMail(index);
          },
          shouldDisplayByCondition: (rowData: SenderSignatureTableDto) => {
            return !rowData.confirmed;
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          icon: 'fa-solid fa-trash',
          hasAccessTooltip: 'Obrišite email adresu',
          noAccessTooltip: 'Nemate ovlašćenja za brisanje email adrese',
          callback: (index: number) => {
            this._confirmationService.confirm({
              header: 'Potvrda brisanja',
              message: 'Da li želite da obrišete email adresu?',
              acceptLabel: 'Obriši',
              rejectLabel: 'Odustani',
              rejectButtonStyleClass: 'p-button-outlined',
              accept: () => {
                this._delete(index);
              },
            });
          },
          shouldDisplayByCondition: (rowData: SenderSignatureTableDto) => {
            return rowData.email !== 'info@ekompanija.com' && !rowData.default;
          },
        },
      ],
    });
  }

  private updateFilterDataAndReload() {
    if (
      (this._skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this._skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }

  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: 'MAILS',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  private _openCreateForm(): void {
    this.dialogConfig = new DynamicDialogConfig(DialogSize.SMALL);
    this.dialogConfig.header = 'Unos email adrese';

    const ref = this._dialogService.open(
      SenderSignatureFormComponent,
      this.dialogConfig
    );

    this._subs.add(
      ref.onClose.subscribe((result) => {
        if (result) {
          this._load();
        }
      })
    );
  }

  private _openDnsSettingsForm(): void {
    this.dialogConfig = new DynamicDialogConfig(DialogSize.MEDIUM_LARGE);
    this.dialogConfig.header = 'DNS podešavanja';
    this.dialogConfig.maximisable = false;
    this.dialogConfig.hideSubmit = true;

    const ref = this._dialogService.open(
      DnsSettingsFormComponent,
      this.dialogConfig
    );

    this._subs.add(
      ref.onClose.subscribe((result) => {
        if (result) {
          this._load();
        }
      })
    );
  }

  private _delete(index: number) {
    this._sharedService.displayLoadingScreen(true, 'Brisanje email adrese...');
    this._subs.add(
      this._client
        .deleteSenderSignature(this._getPaginatedItem(index).id!)
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.data!);
            this._load();
          }
          this._alertService.addFailedMsg(res.messages![0]);
        })
    );
  }

  private _syncPojedinacanEmail(index: number) {
    this._sharedService.displayLoadingScreen(
      true,
      'Sinhronizacija email adrese u toku...'
    );
    this._subs.add(
      this._client
        .syncSenderSignature(this._getPaginatedItem(index).id!)
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.data!);
            this._load();
          }
          this._alertService.addFailedMsg(res.messages![0]);
        })
    );
  }

  private _syncEmailove() {
    this._sharedService.displayLoadingScreen(true, 'Sinhronizacija u toku...');
    this._subs.add(
      this._client.syncSenderSignatures().subscribe((res) => {
        if (res.succeeded) {
          this._alertService.addSuccessMsg(res.data!);
          this._load();
        }
        this._alertService.addFailedMsg(res.messages![0]);
      })
    );
  }

  private _resendConfirmationMail(index: number) {
    this._sharedService.displayLoadingScreen(true, 'Slanje maila potvrde...');
    this._subs.add(
      this._client
        .resendConfirmationMail(this._getPaginatedItem(index).id!)
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.data!);
            this._load();
          }
          this._alertService.addFailedMsg(res.messages![0]);
        })
    );
  }

  private _setAsDefault(index: number) {
    this._sharedService.displayLoadingScreen(
      true,
      'Ažuriranje email adrese...'
    );
    this._subs.add(
      this._client
        .setSignatureAsDefault(this._getPaginatedItem(index).id!)
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.data!);
            this._load();
          }
          this._alertService.addFailedMsg(res.messages![0]);
        })
    );
  }

  private _setAsDefaultForExternal(index: number) {
    this._sharedService.displayLoadingScreen(
      true,
      'Ažuriranje email adrese...'
    );
    this._subs.add(
      this._client
        .setSignatureAsDefaultForExternal(this._getPaginatedItem(index).id!)
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.data!);
            this._load();
          }
          this._alertService.addFailedMsg(res.messages![0]);
        })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
