import { Component, OnInit } from '@angular/core';
import { IIzvestajIzlaznihDokumenataFilter, PodtipIzvestajaVeleprodaja, TipIzvestajaVeleprodaja } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { SelectItem } from 'primeng/api';
import { VeleprodajaIzvestajService } from '../../veleprodaja-izvestaj.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-veleprodaja-page-izvestaji',
  templateUrl: './veleprodaja-page-izvestaji.component.html',
  styleUrls: ['./veleprodaja-page-izvestaji.component.scss']
})
export class VeleprodajaPageIzvestajiComponent implements OnInit {

  private _subs: Subscription = new Subscription();

  izvestajOptions: SelectItem[] = [];
  podtipOptions: SelectItem[] = [];

  form : FormGroupTypeSafe<IIzvestajIzlaznihDokumenataFilter>;
  filterData!: IIzvestajIzlaznihDokumenataFilter;

  disabled : boolean;

  constructor(
    private _service: VeleprodajaIzvestajService,
  ) { }

  ngOnInit(): void {

    this.form = this._service.getForm(this.filterData);

     this.izvestajOptions =
      [
        {label: 'Izveštaj izlaznih dokumenata po artiklima', value: TipIzvestajaVeleprodaja.PO_ARTIKLIMA},
        {label: 'Izveštaj izlaznih dokumenata po partnerima', value: TipIzvestajaVeleprodaja.PO_PARTNERIMA},
      ];

      this.podtipOptions =
        [
          {label: 'Kompletan', value: PodtipIzvestajaVeleprodaja.KOMPLETAN},
          {label: 'Sumiran', value: PodtipIzvestajaVeleprodaja.SUMIRAN},
        ]

      this._subs.add(
        this.form.controls.tipIzvestajaVeleprodaja?.valueChanges.subscribe((res)=>{
          if(res == TipIzvestajaVeleprodaja.PO_PARTNERIMA){
            this.disabled = true;
          }else {
            this.disabled = false;
          }
        })
      );

  }

}
