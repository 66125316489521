<div *ngIf="form" [formGroup]="form">
  <kodit-card-container>
    <div card-body class="p-d-flex p-flex-column">
      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <div class="flex-50">
          <div class="flex-50 page-description-badge">
            <kodit-field
              fieldLabelFor="smer"
              fieldLabel="Promet"
              class="flex-50"
            >
              <kodit-vrsta-racuna-dropdown
                [isForEvidencija]="true"
                [formCtrl]="form.controls?.smer!"
                (onItemChange)="onPrometChange($event)"
                [shouldDisable]="shouldDisableForm"
              >
              </kodit-vrsta-racuna-dropdown>
            </kodit-field>
            <!-- <p-message
              severity="info"
              text="Unesite broj fakture u polje 'Broj dokumenta' i ostali podaci će automatski biti popunjeni"
            ></p-message> -->
          </div>
          <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
            <kodit-field
              fieldLabelFor="tip"
              fieldLabel="Tip dokumenta"
              class="flex-50"
            >
              <kodit-tip-dokumenta-evidencije-dropdown
                [formCtrl]="form.controls.tip!"
                (onItemChange)="onTipDokumentaChange($event)"
                [shouldDisable]="shouldDisableForm"
              >
              </kodit-tip-dokumenta-evidencije-dropdown>
            </kodit-field>

            <kodit-field
              fieldLabelFor="povezani-dokument"
              fieldLabel="{{ form.value.smer != promet.ULAZNI ? 'Broj dokumenta' : 'Broj internog računa' }}"
              class="flex-50"
            >
              <!-- Autocomplete ako je isporuka  -->
              <kodit-povezana-faktura-for-pdv-autocomplete
                *ngIf="_pdvPojedinacnaService.getIsIsporuka"
                [dokumentCtrl]="form.controls.povezanaFaktura!"
                formCtrlId="povezana-faktura"
                [shouldDisable]="shouldDisableForm"
              >
              </kodit-povezana-faktura-for-pdv-autocomplete>
              <!-- Text input ako  -->
              <kodit-text-input
                *ngIf="!_pdvPojedinacnaService.getIsIsporuka"
                formCtrlId="povezana-faktura"
                [formCtrl]="form.controls.brojDokumentaNabavka"
                [shouldDisable]="shouldDisableForm"
              ></kodit-text-input>
            </kodit-field>
          </div>
        </div>
      </div>
      <kodit-divider></kodit-divider>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabel="Broj pojedinačne PDV evidencije"
          fieldLabelFor="pravilo-za-broj"
          class="flex-50"
        >
          <kodit-text-input
            [formCtrl]="form.controls.broj"
            [isDisabled]="shouldDisableForm"
          ></kodit-text-input>
        </kodit-field>
      </div>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field fieldLabel="Godina" fieldLabelFor="godina" class="flex-50">
          <kodit-number-input
            formCtrlId="godina"
            [formCtrl]="form.controls.godina"
            [ctrlMaxLength]="4"
            [ctrlMinValue]="1900"
            [ctrlOnlyIntegers]="true"
            [ctrlUseGrouping]="false"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
        <kodit-field
          fieldLabelFor="datum-evidentiranja"
          fieldLabel="Datum evidentiranja"
          class="flex-50"
        >
          <kodit-calendar
            [disabled]="true"
            formCtrlId="datum-prometa"
            [formCtrl]="form.controls.datumEvidentiranja!"
          ></kodit-calendar>
        </kodit-field>
      </div>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabelFor="vremenski-opseg"
          fieldLabel="Poreski period"
          class="flex-50"
        >
          <kodit-pdv-opseg-dropdown
            formCtrlId="vremenski-opseg"
            [periodValue]="form.value.period!"
            [shouldDisable]="shouldDisableForm"
          ></kodit-pdv-opseg-dropdown>
        </kodit-field>
        <kodit-field fieldLabelFor="period" fieldLabel="Period" class="flex-50">
          <kodit-pdv-period-dropdown
            formCtrlId="period"
            [formCtrl]="form.controls.period"
            [shouldDisable]="shouldDisableForm"
          ></kodit-pdv-period-dropdown>
        </kodit-field>
      </div>

      <div
        class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
        *ngIf="form.value.smer == promet.ULAZNI
        "
      >
        <kodit-field
          fieldLabelFor="osnovOdabira"
          fieldLabel="Osnov odabira dokumenta"
          class="flex-50"
        >
          <kodit-osnov-odabira-dokumenta-dropdown
            [formCtrl]="form.controls.osnovOdabiraDokumenta!"
            formCtrlId="osnov-odabira"
            (onItemChange)="onOsnovaOdabiraChange($event)"
            [shouldDisable]="shouldDisableForm"
          >
          </kodit-osnov-odabira-dokumenta-dropdown>
        </kodit-field>
      </div>

      <!-- <div
        class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
        *ngIf="
          form.controls.osnovOdabiraDokumenta?.value !== osnovOdabira.AVANS &&
          (form.controls.povezanaFaktura?.get('smer'))!.value == promet.ULAZNI
        "
      >
        <kodit-field
          fieldLabelFor="tipPovezanogInternog"
          fieldLabel="Povezani interni račun"
          class="flex-50"
        >
          <kodit-tip-povezanog-internog-dropdown
            [formCtrl]="form.controls.tipPovezanogInternog!"
            (onItemChange)="onTipPovezanogInternogChange($event)"
            formCtrlId="tip-povezanog-internog"
            [shouldDisable]="shouldDisableForm"
          >
          </kodit-tip-povezanog-internog-dropdown>
        </kodit-field>
      </div> -->
    </div>
  </kodit-card-container>
  <div class="p-mt-5">
    <kodit-card-container>
      <div card-title>Podaci stranke</div>

      <div
        card-body
        class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
      >
        <kodit-field
          fieldLabelFor="stranka"
          fieldLabel="Stranka"
          class="flex-50"
        >
          <kodit-stranka-for-pdv-autocomplete
            [strankaCtrl]="form.controls.stranka!"
            formCtrlId="stranka"
            [shouldDisable]="shouldDisableForm"
          ></kodit-stranka-for-pdv-autocomplete>
        </kodit-field>
        <kodit-field
          fieldLabelFor="stranka-id"
          fieldLabel="Identifikator lica kojem se vrši promet (PIB/ PIB i JBKJS/ Poreski broj stranog lica)"
          class="flex-50"
        >
          <kodit-text-input
            formCtrlId="stranka-id"
            [formCtrl]="form.controls.stranka!.get('pibOrJbkjs')!"
            [isDisabled]="true"
          ></kodit-text-input>
        </kodit-field>
      </div>
    </kodit-card-container>
  </div>
  <div class="p-mt-5">
    <div>
      <kodit-card-container
        *ngIf="
          form.controls.tip?.value === tipDokumenta.AVANSNA_FAKTURA ||
          form.controls.osnovOdabiraDokumenta?.value === osnovOdabira.AVANS
        "
      >
        <div card-title>Podaci avansne uplate</div>
        <div card-body>
          <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
            <kodit-field
              fieldLabelFor="datum-avansa"
              fieldLabel="Datum avansa"
              class="flex-50"
            >
              <kodit-calendar
                formCtrlId="datum-avansa"
                [formCtrl]="form.controls.datumAvansa!"
                [disabled]="shouldDisableForm"
              ></kodit-calendar>
            </kodit-field>
            <kodit-field
              fieldLabel="Osnov za avansno plaćanje"
              fieldLabelFor="osnov-avansa"
              class="flex-50"
            >
              <kodit-text-input
                [formCtrl]="form.controls.osnovAvansnogPlacanja"
                [isDisabled]="isKoriguj || shouldDisableForm"
              ></kodit-text-input>
            </kodit-field>
          </div>
        </div>
      </kodit-card-container>
    </div>

    <kodit-card-container
      class="p-mt-5"
      *ngIf="
        form.controls.tip?.value !== tipDokumenta.AVANSNA_FAKTURA &&
        form.controls.osnovOdabiraDokumenta?.value !== osnovOdabira.AVANS
      "
    >
      <div card-title>Povezane fakture</div>

      <div card-body>
        <div>
          <!-- Tip povezanog dokumenta  -->
          <kodit-field
            *ngIf="_pdvPojedinacnaService.getIsIsporuka"
            fieldLabelFor="tip"
            fieldLabel="Tip povezane fakture"
            class="flex-50"
          >
            <kodit-tip-povezanog-dokumenta-dropdown
              [formCtrl]="form.controls.tipPovezaneFakture!"
              (onItemChange)="onTipPovezanogDokumentaChange($event)"
              formCtrlId="tip"
              [shouldDisable]="shouldDisableForm || disabledTipPovezanog"
            >
            </kodit-tip-povezanog-dokumenta-dropdown>
          </kodit-field>

          <!-- Tip povezanog internog -->
          <kodit-field
            *ngIf="!_pdvPojedinacnaService.getIsIsporuka"
            fieldLabelFor="tipPovezanogInternog"
            fieldLabel="Povezani interni račun"
            class="flex-50"
          >
            <kodit-tip-povezanog-internog-dropdown
              [formCtrl]="form.controls.tipPovezanogInternog!"
              (onItemChange)="onTipPovezanogInternogChange($event)"
              formCtrlId="tip-povezanog-internog"
              [shouldDisable]="shouldDisableForm"
            >
            </kodit-tip-povezanog-internog-dropdown>
          </kodit-field>
        </div>
        <!-- Vremenski period -->
        <div
          class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
          *ngIf="
            form.controls.tipPovezaneFakture?.value ===
            tipPovezaneFakture.VREMENSKI_PERIOD
          "
        >
          <kodit-field
            fieldLabelFor="datum-pocetka"
            fieldLabel="Početak"
            class="flex-50"
          >
            <kodit-calendar
              formCtrlId="datum-pocetka"
              [formCtrl]="form.controls.vremenskiPeriodPocetak!"
              [disabled]="shouldDisableForm"
            ></kodit-calendar>
          </kodit-field>
          <kodit-field
            fieldLabelFor="datum-zavrsetka"
            fieldLabel="Završetak"
            class="flex-50"
          >
            <kodit-calendar
              formCtrlId="datum-zavrsetka"
              [formCtrl]="form.controls.vremenskiPeriodZavrsetak!"
              [disabled]="shouldDisableForm"
            ></kodit-calendar>
          </kodit-field>
        </div>
        <!-- Fakture sa SEF-a -->
        <div
          class="p-mt-5"
          *ngIf="
            (_pdvPojedinacnaService.getIsIsporuka &&
              form.controls.tipPovezaneFakture?.value !==
                tipPovezaneFakture.VREMENSKI_PERIOD &&
              form.controls.tipPovezaneFakture?.value !==
                tipPovezaneFakture.EMPTY) ||
            (!_pdvPojedinacnaService.getIsIsporuka &&
              form.controls.tipPovezanogInternog?.value !==
                tipPovezanogInternog.EMPTY)
          "
        >
          <h5>Fakture sa SEF-a</h5>
          <kodit-racun-autocomplete
            formCtrlId="izvorna-faktura"
            [includeTipoveRacuna]="useOnlyTipove!"
            [isUnosPojedinacneEvidencije]="true"
            (onChange)="handleVezaniRacunChange($event)"
            [shouldDisable]="shouldDisableForm"
          ></kodit-racun-autocomplete>
          <kodit-povezani-avansni-racuni
            formCtrlId="povezani-avansni"
            [form39]="form"
            [shouldDisable]="shouldDisableForm"
          >
          </kodit-povezani-avansni-racuni>
        </div>
        <!-- Fakture van SEF-a -->
        <div
          class="p-mt-5"
          *ngIf="
            (_pdvPojedinacnaService.getIsIsporuka &&
              form.controls.tipPovezaneFakture?.value !==
                tipPovezaneFakture.VREMENSKI_PERIOD &&
              form.controls.tipPovezaneFakture?.value !==
                tipPovezaneFakture.EMPTY) ||
            (!_pdvPojedinacnaService.getIsIsporuka &&
              form.controls.tipPovezanogInternog?.value !==
                tipPovezanogInternog.EMPTY)
          "
        >
          <h5>Fakture van SEF-a</h5>
          <div class="p-inputgroup" *ngIf="!shouldDisableForm">
            <input
              style="max-width: 13%"
              #avansniVanSefaInput
              type="text"
              pInputText
              placeholder="Broj fakture"
            />
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-plus"
              styleClass="p-button-warn"
              (click)="handleAvansniVanSefaClick()"
            ></button>
          </div>
          <kodit-povezani-avansni-racuni-van-sefa
            formCtrlId="povezani-avansni-van-sefa"
            [form]="form"
            [shouldDisable]="shouldDisableForm"
          >
          </kodit-povezani-avansni-racuni-van-sefa>
        </div>
      </div>
    </kodit-card-container>
  </div>

  <!-- Promet za isporuku -->
  <div class="p-mt-5" *ngIf="_pdvPojedinacnaService.getIsIsporuka">
    <kodit-card-container>
      <div card-title>Promet</div>
      <div card-body class="p-d-flex p-flex-column gap-7">
        <!-- Stopa 20 -->
        <div class="p-d-flex p-flex-row gap-7 p-fluid">
          <!-- Promet po stopi od 20% - S20, osim OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 20%-S20, osim PDV-RS-OBJ'
                  : 'Promet po stopi od 20%-S20, osim PDV-RS-OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisPromet20"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet20"
                [formCtrl]="form.controls.promet20.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet20"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet20"
                [formCtrl]="form.controls.promet20.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet20"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet20"
                [formCtrl]="form.controls.promet20.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet po stopi od 20%-S20-OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 20%-S20-PDV-RS-OBJ'
                  : 'Promet po stopi od 20%-S20-PDV-RS-OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisPromet20Obj"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet20Obj"
                [formCtrl]="form.controls.promet20Obj.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet20Obj"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet20Obj"
                [formCtrl]="form.controls.promet20Obj.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet20Obj"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet20Obj"
                [formCtrl]="form.controls.promet20Obj.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet bez naknade po stopi od 20%-S20, OBJ -->
          <div *ngIf="shouldDisplayIsporukaBN" class="p-d-flex p-flex-column">
            <div class="stopa-header">
              Promet bez naknade po stopi od 20%-S20-PDV-RS-OBJ
            </div>
            <kodit-field
              fieldLabelFor="opisPromet20BnObj"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet20BnObj"
                [formCtrl]="form.controls.promet20BnObj.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet20BnObj"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet20BnObj"
                [formCtrl]="form.controls.promet20BnObj.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet20BnObj"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="obracunatiPdvPromet20BnObj"
                [formCtrl]="form.controls.promet20BnObj.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
        </div>
        <!-- Stopa 10 -->
        <div class="p-d-flex p-flex-row gap-7 p-fluid">
          <!-- Promet po stopi od 10% - S10, osim OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 10%-S10, osim PDV-RS-OBJ'
                  : 'Promet po stopi od 10%-S10, osim PDV-RS-OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisPromet10"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet10"
                [formCtrl]="form.controls.promet10.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet10"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet10"
                [formCtrl]="form.controls.promet10.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet10"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet10"
                [formCtrl]="form.controls.promet10.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet po stopi od 10%-S10-OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 10%-S10-PDV-RS-OBJ'
                  : 'Promet po stopi od 10%-S10-PDV-RS-OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisPromet10Obj"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet10Obj"
                [formCtrl]="form.controls.promet10Obj.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet10Obj"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet10Obj"
                [formCtrl]="form.controls.promet10Obj.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet20Obj"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet20Obj"
                [formCtrl]="form.controls.promet10Obj.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet bez naknade po stopi od 10%-S10, OBJ -->
          <div *ngIf="shouldDisplayIsporukaBN" class="p-d-flex p-flex-column">
            <div class="stopa-header">
              Promet bez naknade po stopi od 10%-S10-PDV-RS-OBJ
            </div>
            <kodit-field
              fieldLabelFor="opisPromet10BnObj"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet10BnObj"
                [formCtrl]="form.controls.promet10BnObj.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet10BnObj"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet10BnObj"
                [formCtrl]="form.controls.promet10BnObj.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet10BnObj"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet10BnObj"
                [formCtrl]="form.controls.promet10BnObj.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
        </div>
      </div>
    </kodit-card-container>
  </div>
  <!-- Promet za nabavku -->
  <div class="p-mt-5" *ngIf="!_pdvPojedinacnaService.getIsIsporuka">
    <kodit-card-container>
      <div card-title>Promet za naknadu</div>
      <div card-body class="p-d-flex p-flex-column gap-7">
        <!-- Stopa 20 -->
        <div class="p-d-flex p-flex-row gap-7 p-fluid">
          <!-- Promet po stopi od 20% - AE20, osim OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 20%-AE20, osim OBJ'
                  : 'Promet po stopi od 20%-AE20, osim OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisPromet20"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet20"
                [formCtrl]="form.controls.promet20.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet20"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet20"
                [formCtrl]="form.controls.promet20.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet20"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet20"
                [formCtrl]="form.controls.promet20.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet po stopi od 20%-AE20-OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 20%-AE20-OBJ'
                  : 'Promet po stopi od 20%-AE20-OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisPromet20Obj"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet20Obj"
                [formCtrl]="form.controls.promet20Obj.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet20Obj"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet20Obj"
                [formCtrl]="form.controls.promet20Obj.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet20Obj"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet20Obj"
                [formCtrl]="form.controls.promet20Obj.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet bez naknade po stopi od 20%-AE20, osim OBJ -->
          <div *ngIf="shouldDisplayNabavkaBN" class="p-d-flex p-flex-column">
            <div class="stopa-header">
              Promet bez naknade po stopi od 20%-AE20, osim OBJ
            </div>
            <kodit-field
              fieldLabelFor="opisPromet20Bn"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet20Bn"
                [formCtrl]="form.controls.promet20Bn.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet20Bn"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet20Bn"
                [formCtrl]="form.controls.promet20Bn.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet20Bn"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet20Bn"
                [formCtrl]="form.controls.promet20Bn.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet bez naknade po stopi od 20%-AE20-OBJ -->
          <div *ngIf="shouldDisplayNabavkaBN" class="p-d-flex p-flex-column">
            <div class="stopa-header">
              Promet bez naknade po stopi od 20%-AE20-OBJ
            </div>
            <kodit-field
              fieldLabelFor="opisPromet20BnObj"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet20BnObj"
                [formCtrl]="form.controls.promet20BnObj.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet20BnObj"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet20BnObj"
                [formCtrl]="form.controls.promet20BnObj.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet20BnObj"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet20BnObj"
                [formCtrl]="form.controls.promet20BnObj.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
        </div>
        <!-- Stopa 10 -->
        <div class="p-d-flex p-flex-row gap-7 p-fluid">
          <!-- Promet po stopi od 10% - AE10, osim OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 10%-AE10, osim OBJ'
                  : 'Promet po stopi od 10%-AE10, osim OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisPromet10"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet10"
                [formCtrl]="form.controls.promet10.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet10"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet10"
                [formCtrl]="form.controls.promet10.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet10"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet10"
                [formCtrl]="form.controls.promet10.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet po stopi od 10%-AE10-OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 10%-AE10-OBJ'
                  : 'Promet po stopi od 10%-AE10-OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisPromet10Obj"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet10Obj"
                [formCtrl]="form.controls.promet10Obj.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet10Obj"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet10Obj"
                [formCtrl]="form.controls.promet10Obj.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet10Obj"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet10Obj"
                [formCtrl]="form.controls.promet10Obj.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet bez naknade po stopi od 10%-AE10, osim OBJ -->
          <div *ngIf="shouldDisplayNabavkaBN" class="p-d-flex p-flex-column">
            <div class="stopa-header">
              Promet bez naknade po stopi od 10%-AE10, osim OBJ
            </div>
            <kodit-field
              fieldLabelFor="opisPromet10Bn"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet10Bn"
                [formCtrl]="form.controls.promet10Bn.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet10Bn"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet10Bn"
                [formCtrl]="form.controls.promet10Bn.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet10Bn"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet10Bn"
                [formCtrl]="form.controls.promet10Bn.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet bez naknade po stopi od 10%-AE10-OBJ -->
          <div *ngIf="shouldDisplayNabavkaBN" class="p-d-flex p-flex-column">
            <div class="stopa-header">
              Promet bez naknade po stopi od 10%-AE10-OBJ
            </div>
            <kodit-field
              fieldLabelFor="opisPromet10BnObj"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisPromet10BnObj"
                [formCtrl]="form.controls.promet10BnObj.get('opis')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaPromet10BnObj"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="osnovicaPromet10BnObj"
                [formCtrl]="form.controls.promet10BnObj.get('osnovica')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatiPdvPromet10BnObj"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                formCtrlId="obracunatiPdvPromet10BnObj"
                [formCtrl]="form.controls.promet10BnObj.get('obracunatiPdv')!"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
        </div>
      </div>
    </kodit-card-container>
  </div>

  <div class="p-mt-5" card style="position: sticky; bottom: 0; z-index: 9">
    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between">
      <div
        class="dodatne-akcije p-d-flex p-flex-column p-jc-around p-ml-3"
      ></div>
      <div class="primarne-akcije p-as-center p-d-flex gap-6">
        <ng-content></ng-content>
        <button
          *ngIf="shouldDisableForm"
          pButton
          pRipple
          type="button"
          label="Zatvori"
          class="p-button-secondary p-button-outlined"
          pTooltip="Zatvori"
          tooltipPosition="bottom"
          (click)="handleRouteBack()"
          [disabled]="false"
        ></button>
        <button
          *ngIf="!shouldDisableForm"
          pButton
          pRipple
          icon="far fa-window-close"
          type="button"
          label="Otkaži"
          class="p-button-secondary p-button-outlined"
          pTooltip="Otkaži PDV evidenciju"
          tooltipPosition="bottom"
          (click)="handleCancel()"
          [disabled]="false"
        ></button>
        <button
          *ngIf="!shouldDisableForm"
          pButton
          icon="fas fa-save"
          type="submit"
          label="Sačuvaj"
          class="p-button-success"
          pTooltip="Sačuvaj PDV evidenciju"
          tooltipPosition="bottom"
          (click)="handleSave()"
          [disabled]="false"
          [loading]="false"
        ></button>
      </div>
    </div>
  </div>
</div>
